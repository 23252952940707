import React from 'react'

const InfoCard = ({ icon, title, value }) => (
  <div className='bg-white/10 rounded-lg p-4 flex flex-col items-center justify-center hover:bg-white/20 transition duration-300'>
    <div className='text-3xl mb-2 text-[#16a34a]'>{icon}</div>
    <p className='text-sm'>{title}</p>
    <p className='text-xl font-bold'>{value}</p>
  </div>
)

export default InfoCard
