import React from 'react'
import Layout from '../Layout'
import Topbar from '../components/Layout/Topbar'
import TeacherMain from '../components/Teacher/TeacherMain'

const Teacher = () => {
  return (
    <Layout>
      <Topbar title='Teachers' />
      <TeacherMain />
    </Layout>
  )
}

export default Teacher
