export const dummyData = {
  totalStudents: 1405,
  totalTeachers: 24,
  totalClasses: 12,
  totalBuses: 12,
  totalEnquiries: 10,
  todayFees: 50000,
  monthlyFees: 150000,
  feesByClass: [
    { class: 'Class 1', fees: 15000 },
    { class: 'Class 2', fees: 20000 },
    { class: 'Class 3', fees: 25000 },
    { class: 'Class 4', fees: 30000 },
    { class: 'Class 5', fees: 35000 },
    { class: 'Class 6', fees: 25000 },
    { class: 'Class 7', fees: 20000 },
    { class: 'Class 8', fees: 15000 },
    { class: 'Class 9', fees: 10000 },
  ],
  feesByBatch: [
    { name: 'Class 12', value: 18.66 },
    { name: 'Class 11', value: 18.66 },
    { name: 'Class 10', value: 12.66 },
    { name: 'Class 9', value: 18.66 },
    { name: 'Class 8', value: 20.66 },
    { name: 'Class 7', value: 10.7 },
  ],
  announcements: [
    {
      title: 'Annual Exam',
      content:
        'All class annual exam will start on 03 November 2024. Kindly keep in mind.',
    },
    {
      title: 'Summercamp Vacation',
      content: 'Diwali vacation will start from 4 Nov - 10 Nov 2024.',
    },
    {
      title: 'Annual Function',
      content: 'We will arrange Annual Function on 12 Nov & 13 Nov 2024.',
    },
  ],
}
