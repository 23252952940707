import {
  Chat,
  Close as CloseIcon,
  CoPresent,
  Dashboard,
  ExitToApp,
  Face6,
  GppGood,
  MenuBook,
  Menu as MenuIcon,
  Paid,
  Person,
  School,
} from '@mui/icons-material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../redux/userSlice'

const menuItems = [
  { name: 'Dashboard', icon: Dashboard, path: '/dashboard' },
  { name: 'Students', icon: Face6, path: '/students' },
  { name: 'Class', icon: School, path: '/class' },
  { name: 'Teachers', icon: CoPresent, path: '/teachers' },
  { name: 'Subjects', icon: MenuBook, path: '/subjects' },
  { name: 'Publish', icon: GppGood, path: '/publish' },
  { name: 'Reports', icon: Paid, path: '/reports' },
  { name: 'Users', icon: Person, path: '/users' },
  { name: 'Chat', icon: Chat, path: '/chat' },
]

const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <>
      <button
        className='fixed top-4 left-4 z-50 p-2 bg-green-600 rounded-full shadow-lg lg:hidden transition-all duration-300 hover:bg-green-700'
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <CloseIcon className='w-6 h-6 text-white' />
        ) : (
          <MenuIcon className='w-6 h-6 text-white' />
        )}
      </button>
      <aside
        className={`fixed inset-y-0 left-0 z-40 w-60 bg-[#0b0a45] text-white transition-all duration-300 ease-in-out transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
      >
        <div className='flex flex-col h-full'>
          <div className='p-5 bg-gradient-to-b'>
            <div className='w-20 mx-auto overflow-hidden shadow-lg'>
              <img
                src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logo-white.png?raw=true'
                alt='Logo'
                className='w-full h-full object-cover'
              />
            </div>
            <h1 className='mt-4 text-xl font-bold text-center text-white'>
              The Orion School
            </h1>
            <p className='text-sm text-center text-green-200'>
              Management System
            </p>
          </div>
          <nav className='flex-1 px-3 py-4 space-y-1 overflow-y-auto'>
            {menuItems.map((item) => {
              const Icon = item.icon
              return (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`flex items-center px-4 py-3 text-sm rounded-lg transition-all duration-200 ${
                    location.pathname === item.path
                      ? 'bg-green-600 text-white shadow-md'
                      : 'text-gray-300 hover:bg-green-600/20 hover:text-white'
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  <Icon className='w-5 h-5 mr-3' />
                  {item.name}
                </Link>
              )
            })}
          </nav>
          <div className='p-4'>
            <button
              className='flex items-center justify-center w-full px-4 py-2 text-sm text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors shadow-md'
              onClick={handleLogout}
            >
              <ExitToApp className='w-5 h-5 mr-2' />
              Logout
            </button>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
