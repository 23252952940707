import {
  Calendar,
  CreditCard,
  Filter,
  GraduationCap,
  Search,
} from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import FeeDataTable from '../../components/Fee/FeeDataTable'
import Topbar from '../../components/Layout/Topbar'
import { axiosInstance } from '../../config'
import Layout from '../../Layout'
import Loader from '../../Loader/Loader'

const FilterSelect = ({ label, value, onChange, options, icon: Icon }) => (
  <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4'>
    <label
      className='block text-sm font-medium text-gray-700 mb-1'
      htmlFor={label}
    >
      {label}
    </label>
    <div className='relative'>
      <select
        id={label}
        value={value}
        onChange={onChange}
        className='w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-white'
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
        <Icon className='h-5 w-5 text-gray-400' />
      </div>
    </div>
  </div>
)

export default function Months() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const currentMonth = new Date().getMonth()
  const [selectedMonth, setSelectedMonth] = useState(monthNames[currentMonth])
  const [feeStatus, setFeeStatus] = useState('')
  const [studentClass, setStudentClass] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const { data, status, error, refetch } = useQuery(
    ['fee-slips-data', selectedMonth, feeStatus, studentClass, searchTerm],
    async () => {
      const endpoint = `/fee/feeslips-by-month/${selectedMonth}?feeStatus=${feeStatus}&class=${studentClass}&search=${searchTerm}`
      const response = await axiosInstance.get(endpoint)
      if (response.status === 404) {
        throw new Error(response.data.message)
      }
      return response.data
    },
    {
      retry: false,
      onError: (err) => {
        console.error('Error fetching data:', err)
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [selectedMonth, feeStatus, studentClass, searchTerm, refetch])

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value)
  }

  return (
    <Layout>
      <Topbar title='Fee Slips by Month' />
      <div className='container mx-auto px-4 py-8'>
        <div className='bg-white shadow-lg rounded-lg p-6 mb-8'>
          <h2 className='text-2xl font-bold mb-6 text-gray-800 flex items-center'>
            <Filter className='mr-2 h-6 w-6 text-indigo-600' />
            Filters
          </h2>
          <div className='flex flex-wrap -mx-2'>
            <FilterSelect
              label='Month'
              value={selectedMonth}
              onChange={handleInputChange(setSelectedMonth)}
              options={monthNames.map((month) => ({
                value: month,
                label: month,
              }))}
              icon={Calendar}
            />
            <FilterSelect
              label='Fee Status'
              value={feeStatus}
              onChange={handleInputChange(setFeeStatus)}
              options={[
                { value: '', label: 'All' },
                { value: 'Paid', label: 'Paid' },
                { value: 'Unpaid', label: 'Unpaid' },
              ]}
              icon={CreditCard}
            />
            <FilterSelect
              label='Class'
              value={studentClass}
              onChange={handleInputChange(setStudentClass)}
              options={[
                { value: '', label: 'All Classes' },
                { value: 'PG', label: 'PG' },
                { value: 'Nursery', label: 'Nursery' },
                { value: 'KG', label: 'KG' },
                { value: 'Grade 1', label: 'Grade 1' },
                { value: 'Grade 2', label: 'Grade 2' },
                { value: 'Grade 3', label: 'Grade 3' },
                { value: 'Grade 4', label: 'Grade 4' },
                { value: 'Grade 5', label: 'Grade 5' },
                { value: 'Grade 6', label: 'Grade 6' },
                { value: 'Grade 7', label: 'Grade 7' },
              ]}
              icon={GraduationCap}
            />
            <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4'>
              <label
                className='block text-sm font-medium text-gray-700 mb-1'
                htmlFor='search'
              >
                Search
              </label>
              <div className='relative'>
                <input
                  type='text'
                  id='search'
                  className='w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500'
                  placeholder='Search by name or ID'
                  value={searchTerm}
                  onChange={handleInputChange(setSearchTerm)}
                />
                <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                  <Search className='h-5 w-5 text-gray-400' />
                </div>
              </div>
            </div>
          </div>
        </div>

        {status === 'loading' ? (
          <div className='flex justify-center items-center h-64'>
            <Loader msg='Loading Invoices...' />
          </div>
        ) : error ? (
          <div
            className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8 rounded-r-lg'
            role='alert'
          >
            <p className='font-bold'>Error</p>
            <p>{error.message}</p>
          </div>
        ) : (
          <div className='bg-white shadow-lg rounded-lg overflow-hidden'>
            <FeeDataTable MonthlyData={data} />
          </div>
        )}
      </div>
    </Layout>
  )
}
