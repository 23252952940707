import {
  CalendarToday,
  Close,
  Edit,
  EmojiEmotions,
  LibraryBooks,
  Person,
  School,
  Timer,
  Visibility,
} from '@mui/icons-material'
import React, { useState } from 'react'

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4'>
      <div className='bg-gray-900 p-6 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto'>
        <div className='flex justify-between items-center mb-4'>
          <h3 className='text-2xl font-bold text-white'>{title}</h3>
          <button
            onClick={onClose}
            className='text-white hover:text-gray-300 transition-colors'
          >
            <Close />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

const ClassCard = ({ classInfo }) => {
  const [isViewOpen, setIsViewOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Form submitted')
    setIsEditOpen(false)
  }

  return (
    <>
      <div className='flex flex-col items-center justify-between p-6 m-4 rounded-lg bg-gradient-to-br from-[#020131] to-[#2a2a5a] border border-[#5c6bf0] shadow-[inset_0px_0px_20px_0px_rgba(40,115,235,0.5)] text-white min-h-[300px] w-full sm:w-[calc(50%-2rem)] md:w-[calc(33.333%-2rem)] lg:w-[300px] transition-all duration-300 hover:shadow-[inset_0px_0px_30px_0px_rgba(40,115,235,0.8)] hover:scale-105'>
        <h3 className='text-2xl font-bold text-center mb-4'>
          {classInfo.name}
        </h3>

        <div className='flex flex-col items-center space-y-3 mb-6'>
          <div className='flex items-center'>
            <EmojiEmotions className='mr-2 text-yellow-400' />
            <span className='mr-2 text-gray-300'>Students:</span>
            <span className='font-semibold text-white'>
              {classInfo.students}
            </span>
          </div>
          <div className='flex items-center'>
            <LibraryBooks className='mr-2 text-green-400' />
            <span className='mr-2 text-gray-300'>Teachers:</span>
            <span className='font-semibold text-white'>
              {classInfo.teachers}
            </span>
          </div>
          <div className='flex items-center'>
            <School className='mr-2 text-blue-400' />
            <span className='mr-2 text-gray-300'>Subjects:</span>
            <span className='font-semibold text-white'>
              {classInfo.subjects.length}
            </span>
          </div>
        </div>

        <div className='flex space-x-3'>
          <button
            onClick={() => setIsViewOpen(true)}
            className='flex items-center px-4 py-2 bg-gradient-to-r from-[#3aa933] to-[#45c940] hover:from-[#2d8027] hover:to-[#37a334] rounded-lg transition duration-300 shadow-md'
          >
            <Visibility className='mr-2' fontSize='small' />
            View
          </button>
          <button
            onClick={() => setIsEditOpen(true)}
            className='flex items-center px-4 py-2 bg-gradient-to-r from-[#0033ff] to-[#4d79ff] hover:from-[#0026cc] hover:to-[#3366ff] rounded-lg transition duration-300 shadow-md'
          >
            <Edit className='mr-2' fontSize='small' />
            Edit
          </button>
        </div>
      </div>

      <Modal
        isOpen={isViewOpen}
        onClose={() => setIsViewOpen(false)}
        title={`Class Details: ${classInfo.name}`}
      >
        <div className='text-white space-y-4'>
          <div className='grid grid-cols-2 gap-4'>
            <div className='flex items-center'>
              <EmojiEmotions className='mr-2 text-yellow-400' />
              <span className='font-semibold'>Students:</span>
              <span className='ml-2'>{classInfo.students}</span>
            </div>
            <div className='flex items-center'>
              <LibraryBooks className='mr-2 text-green-400' />
              <span className='font-semibold'>Teachers:</span>
              <span className='ml-2'>{classInfo.teachers}</span>
            </div>
            <div className='flex items-center'>
              <CalendarToday className='mr-2 text-purple-400' />
              <span className='font-semibold'>Schedule:</span>
              <span className='ml-2'>{classInfo.schedule}</span>
            </div>
            <div className='flex items-center'>
              <Timer className='mr-2 text-red-400' />
              <span className='font-semibold'>Duration:</span>
              <span className='ml-2'>{classInfo.duration}</span>
            </div>
          </div>
          <div>
            <h4 className='font-semibold text-lg mb-2'>Subjects:</h4>
            <ul className='list-disc list-inside'>
              {classInfo.subjects.map((subject, index) => (
                <li key={index}>{subject}</li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className='font-semibold text-lg mb-2'>Teachers:</h4>
            <ul className='list-disc list-inside'>
              {classInfo.teacherList.map((teacher, index) => (
                <li key={index}>{teacher}</li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        title={`Edit Class: ${classInfo.name}`}
      >
        <form onSubmit={handleSubmit} className='text-white space-y-4'>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <label className='block mb-1'>Class Name</label>
              <input
                type='text'
                defaultValue={classInfo.name}
                className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
              />
            </div>
            <div>
              <label className='block mb-1'>Number of Students</label>
              <input
                type='number'
                defaultValue={classInfo.students}
                className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
              />
            </div>
            <div>
              <label className='block mb-1'>Number of Teachers</label>
              <input
                type='number'
                defaultValue={classInfo.teachers}
                className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
              />
            </div>
            <div>
              <label className='block mb-1'>Schedule</label>
              <input
                type='text'
                defaultValue={classInfo.schedule}
                className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
              />
            </div>
          </div>
          <div>
            <label className='block mb-1'>Subjects (comma-separated)</label>
            <input
              type='text'
              defaultValue={classInfo.subjects.join(', ')}
              className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
            />
          </div>
          <div>
            <label className='block mb-1'>Teachers (comma-separated)</label>
            <input
              type='text'
              defaultValue={classInfo.teacherList.join(', ')}
              className='w-full px-3 py-2 bg-gray-800 rounded border border-gray-700'
            />
          </div>
          <button
            type='submit'
            className='w-full py-2 bg-blue-600 hover:bg-blue-700 rounded transition duration-300'
          >
            Save Changes
          </button>
        </form>
      </Modal>
    </>
  )
}

const MainSection = () => {
  const classes = [
    {
      name: 'PG Dolphin',
      students: 20,
      teachers: 2,
      subjects: ['Basic Math', 'Urdu', 'Art', 'Islamiyat'],
      teacherList: ['Ms. Fatima', 'Mr. Ali'],
      schedule: 'Mon-Fri, 8AM-12PM',
      duration: '4 hours daily',
    },
    {
      name: 'Nursery Dove',
      students: 18,
      teachers: 2,
      subjects: ['Alphabets', 'Counting', 'Coloring', 'Rhymes'],
      teacherList: ['Ms. Ayesha', 'Mrs. Saima'],
      schedule: 'Mon-Fri, 8AM-12PM',
      duration: '4 hours daily',
    },
    {
      name: 'KG Swan',
      students: 22,
      teachers: 3,
      subjects: ['Basic Reading', 'Writing', 'Math', 'General Knowledge'],
      teacherList: ['Mr. Usman', 'Ms. Hira', 'Mrs. Sana'],
      schedule: 'Mon-Fri, 8AM-1PM',
      duration: '5 hours daily',
    },
    {
      name: 'Grade 1 Red',
      students: 25,
      teachers: 3,
      subjects: ['English', 'Math', 'Science', 'Urdu', 'Art'],
      teacherList: ['Ms. Maria', 'Mr. Ahmed', 'Mrs. Iqra'],
      schedule: 'Mon-Fri, 8AM-2PM',
      duration: '6 hours daily',
    },
    {
      name: 'Grade 1 Green',
      students: 24,
      teachers: 3,
      subjects: ['English', 'Math', 'Science', 'Urdu', 'Music'],
      teacherList: ['Mr. Hassan', 'Ms. Nida', 'Mrs. Zainab'],
      schedule: 'Mon-Fri, 8AM-2PM',
      duration: '6 hours daily',
    },
    {
      name: 'Grade 1 Blue',
      students: 23,
      teachers: 3,
      subjects: ['English', 'Math', 'Science', 'Urdu', 'Physical Education'],
      teacherList: ['Mrs. Rabia', 'Mr. Fahad', 'Ms. Amna'],
      schedule: 'Mon-Fri, 8AM-2PM',
      duration: '6 hours daily',
    },
  ]

  return (
    <div className='p-6  min-h-screen'>
      <h2 className='text-3xl font-bold text-white mb-8 text-center'>
        Class Information
      </h2>
      <div className='flex flex-wrap justify-center'>
        {classes.map((classInfo, index) => (
          <ClassCard key={index} classInfo={classInfo} />
        ))}
      </div>
    </div>
  )
}

export default MainSection
