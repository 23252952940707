import { motion } from 'framer-motion'
import {
  BookOpen,
  CalendarDays,
  CreditCard,
  FileText,
  GraduationCap,
  Umbrella,
  UserCircle,
  Users,
} from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../Layout'
import Topbar from '../../components/Layout/Topbar'

const menuItems = [
  {
    name: 'Admissions',
    icon: Users,
    link: 'all-admissions',
    color: 'from-blue-500 to-blue-600',
    dropdownItems: [
      { name: 'New Admission', link: '/students/all-admissions/new' },
      { name: 'Deleted Admissions', link: '#' },
      { name: 'Admission Process', link: '#' },
    ],
  },
  {
    name: 'Students',
    icon: UserCircle,
    link: 'enrolled',
    color: 'from-green-500 to-green-600',
    dropdownItems: [
      { name: 'Student Profile', link: '#' },
      { name: 'Student List', link: '#' },
      { name: 'Student Performance', link: '#' },
    ],
  },
  {
    name: 'Fee Challans',
    icon: CreditCard,
    link: 'fee',
    color: 'from-yellow-500 to-yellow-600',
    dropdownItems: [
      { name: 'Generate Challan', link: '#' },
      { name: 'Fee Structure', link: '#' },
      { name: 'Payment History', link: '#' },
    ],
  },
  {
    name: 'Summer Camp',
    icon: Umbrella,
    link: 'summer-camp',
    color: 'from-red-500 to-red-600',
    dropdownItems: [
      { name: 'Camp Registration', link: '#' },
      { name: 'Camp Schedule', link: '#' },
      { name: 'Camp Activities', link: '#' },
    ],
  },
  {
    name: 'Class',
    icon: GraduationCap,
    link: '/class',
    color: 'from-purple-500 to-purple-600',
    dropdownItems: [
      { name: 'Class List', link: '#' },
      { name: 'Class Schedule', link: '#' },
      { name: 'Class Performance', link: '#' },
    ],
  },
  {
    name: 'Results',
    icon: FileText,
    link: '#',
    color: 'from-pink-500 to-pink-600',
    dropdownItems: [
      { name: 'Exam Results', link: '#' },
      { name: 'Result Analysis', link: '#' },
      { name: 'Publish Results', link: '#' },
    ],
  },
  {
    name: 'Subjects',
    icon: BookOpen,
    link: '/subjects',
    color: 'from-indigo-500 to-indigo-600',
    dropdownItems: [
      { name: 'Subject List', link: '#' },
      { name: 'Add Subject', link: '#' },
      { name: 'Subject Teachers', link: '#' },
    ],
  },
  {
    name: 'Attendance',
    icon: CalendarDays,
    link: '#',
    color: 'from-teal-500 to-teal-600',
    dropdownItems: [
      { name: 'Mark Attendance', link: '#' },
      { name: 'Attendance Report', link: '#' },
      { name: 'Attendance Summary', link: '#' },
    ],
  },
]

const Dropdown = ({ items, color }) => (
  <div className={`mt-2 py-2 bg-gradient-to-br ${color} rounded-b-xl`}>
    {items.map((item, index) => (
      <Link
        key={index}
        to={item.link}
        className='block px-4 py-2 text-sm text-white hover:bg-white hover:bg-opacity-20'
      >
        {item.name}
      </Link>
    ))}
  </div>
)

const MenuItem = ({ name, icon: Icon, link, color, dropdownItems }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className='flex flex-col'
    >
      <Link
        to={link}
        className={`bg-gradient-to-br ${color} rounded-t-xl shadow-lg overflow-hidden`}
      >
        <div className='p-6 flex flex-col items-center'>
          <Icon className='text-white mb-4' size={64} strokeWidth={1.5} />
          <h3 className='text-white text-xl font-semibold text-center'>
            {name}
          </h3>
        </div>
      </Link>
      <Dropdown items={dropdownItems} color={color} />
    </motion.div>
  )
}

const Students = () => {
  return (
    <Layout>
      <Topbar title='Students' />
      <div className='min-h-screen bg-gradient-to-br  p-8'>
        <div className='max-w-7xl mx-auto'>
          <motion.h1
            className='text-4xl md:text-5xl font-bold text-center text-green-600 mb-12'
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Students Control Panel
          </motion.h1>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'>
            {menuItems.map((item, index) => (
              <motion.div
                key={item.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <MenuItem {...item} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Students
