import { TrendingUp } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { axiosInstance } from '../../config'

const MonthlyFeesCard = () => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [currentMonthFees, setCurrentMonthFees] = useState(0)
  const [previousMonthFees, setPreviousMonthFees] = useState(0)

  const { data: totalFees } = useQuery('finance-total', async () => {
    const res = await axiosInstance.get(`/fee/total-paid-fee-amount`)
    return res.data
  })

  const { data: monthlyFees } = useQuery('monthly-fees', async () => {
    const res = await axiosInstance.get(`/fee/monthly-fees`)
    return res.data
  })

  useEffect(() => {
    if (totalFees) {
      setTotalAmount(totalFees.totalAmount || 0)
    }
  }, [totalFees])

  useEffect(() => {
    if (monthlyFees) {
      setCurrentMonthFees(monthlyFees.currentMonth || 0)
      setPreviousMonthFees(monthlyFees.previousMonth || 0)
    }
  }, [monthlyFees])

  const getCurrentMonth = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const currentDate = new Date()
    return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`
  }

  const getPreviousMonth = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)
    return `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`
  }

  return (
    <div className='bg-gradient-to-br from-green-800 to-green-900 rounded-lg p-6 shadow-lg h-full'>
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-xl font-bold text-white'>
          Monthly Fees Collections
        </h2>
        <div className='bg-green-700 rounded-full p-2'>
          <TrendingUp className='text-white' />
        </div>
      </div>
      <div className='bg-white/10 rounded-lg p-4 mb-4'>
        <p className='text-sm text-green-300 mb-2'>{getCurrentMonth()}</p>
        <p className='text-4xl font-bold text-white'>
          PKR {currentMonthFees.toLocaleString()}
        </p>
      </div>
      <div className='bg-white/10 rounded-lg p-4 mb-4'>
        <p className='text-sm text-green-300 mb-2'>{getPreviousMonth()}</p>
        <p className='text-4xl font-bold text-white'>
          PKR {previousMonthFees.toLocaleString()}
        </p>
      </div>
      <div className='mt-4 flex justify-between items-center'>
        <p className='text-sm text-green-300'>Total</p>
        <p className='text-sm font-semibold text-white'>
          PKR {totalAmount.toLocaleString()}
        </p>
      </div>
    </div>
  )
}

export default MonthlyFeesCard
