import React from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8884D8',
  '#82ca9d',
  '#a4de6c',
  '#d0ed57',
]
const PERFORMANCE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const dummyData = {
  studentDistribution: [
    { grade: 'Grade 1', students: 120 },
    { grade: 'Grade 2', students: 115 },
    { grade: 'Grade 3', students: 130 },
    { grade: 'Grade 4', students: 125 },
    { grade: 'Grade 5', students: 110 },
    { grade: 'Grade 6', students: 105 },
    { grade: 'Grade 7', students: 100 },
    { grade: 'Grade 8', students: 95 },
  ],
  performanceDistribution: [
    { level: 'Excellent', value: 30 },
    { level: 'Good', value: 45 },
    { level: 'Average', value: 20 },
    { level: 'Needs Improvement', value: 5 },
  ],
}

const PieChartComponent = ({ data }) => (
  <div className='w-full md:w-1/2 mb-4 md:mb-0'>
    <h3 className='text-center text-sm font-semibold mb-2 text-blue-100'>
      Academic Performance Distribution
    </h3>
    <ResponsiveContainer width='100%' height={200}>
      <PieChart>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          innerRadius={60}
          outerRadius={80}
          fill='#8884d8'
          paddingAngle={5}
          dataKey='value'
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={PERFORMANCE_COLORS[index % PERFORMANCE_COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            backgroundColor: '#1e40af',
            border: 'none',
            color: 'white',
          }}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  </div>
)

const BarChartComponent = ({ data }) => (
  <div className='w-full md:w-1/2'>
    <h3 className='text-center text-sm font-semibold mb-2 text-blue-100'>
      Student Distribution by Grade
    </h3>
    <ResponsiveContainer width='100%' height={200}>
      <BarChart data={data}>
        <XAxis dataKey='grade' stroke='#e0f2fe' />
        <YAxis stroke='#e0f2fe' />
        <Tooltip
          contentStyle={{
            backgroundColor: '#1e40af',
            border: 'none',
            color: 'white',
          }}
        />
        <Bar dataKey='students' fill='#3b82f6'>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
)

const StudentDistributionAndPerformanceChart = () => (
  <div className='bg-white/10 rounded-lg p-6 shadow-lg'>
    <h2 className='text-xl font-semibold mb-4 text-blue-100'>
      Student Distribution and Academic Performance
    </h2>
    <div className='flex flex-col md:flex-row justify-around'>
      <BarChartComponent data={dummyData.studentDistribution} />
      <PieChartComponent data={dummyData.performanceDistribution} />
    </div>
  </div>
)

export default StudentDistributionAndPerformanceChart
