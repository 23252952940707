import { AlertCircle, CheckCircle, X } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'

const Modal = ({ isOpen, onClose, title, children, type }) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isOpen])

  if (!isOpen) return null

  const configs = {
    success: {
      bgColor: 'bg-blue-50',
      borderColor: 'border-blue-500',
      textColor: 'text-blue-800',
      icon: <CheckCircle className='w-6 h-6 text-blue-500' />,
      buttonColor: 'bg-blue-500 hover:bg-blue-600',
    },
    error: {
      bgColor: 'bg-red-50',
      borderColor: 'border-red-500',
      textColor: 'text-red-800',
      icon: <AlertCircle className='w-6 h-6 text-red-500' />,
      buttonColor: 'bg-red-500 hover:bg-red-600',
    },
  }

  const { bgColor, borderColor, textColor, icon, buttonColor } = configs[type]

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4'
      onClick={onClose}
    >
      <div
        ref={modalRef}
        className={`${bgColor} ${borderColor} border-2 p-6 rounded-lg shadow-lg max-w-md w-full`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className='flex justify-between items-center mb-4'>
          <div className='flex items-center space-x-2'>
            {icon}
            <h2 className={`text-2xl font-bold ${textColor}`}>{title}</h2>
          </div>
          <button
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out'
          >
            <X className='w-6 h-6' />
          </button>
        </div>
        <div className={`mb-6 ${textColor}`}>{children}</div>
        <div className='flex justify-end'>
          <button
            onClick={onClose}
            className={`px-4 py-2 ${buttonColor} text-white rounded-md transition duration-300 ease-in-out flex items-center space-x-2`}
          >
            <span>Close</span>
            <X className='w-4 h-4' />
          </button>
        </div>
      </div>
    </div>
  )
}

const AddStudent = () => {
  const initialFormState = {
    studentInfo: {
      name: '',
      prevSchool: '',
      grade: '',
      dob: '',
      gender: '',
      nationality: '',
    },
    extracurricular: {
      sports: [],
      clubs: [],
    },
    fatherInfo: {
      name: '',
      cnic: '',
      cell: '',
      email: '',
      nationality: '',
      company: '',
      occupation: '',
      education: '',
    },
    motherInfo: {
      name: '',
      occupation: '',
      education: '',
      company: '',
      cell: '',
      isWorking: false,
      job: '',
    },
    familyInfo: {
      address: '',
      firstLanguage: '',
      otherLanguages: [],
      siblings: [],
    },
    medicalHistory: {
      allergies: '',
      asthma: false,
      speechDefect: false,
      visionProblem: false,
      hearingProblem: false,
      learningProblem: false,
      compulsiveDisorder: false,
      medication: '',
      otherConditions: '',
    },
    notes: '',
  }
  const [formData, setFormData] = useState(initialFormState)
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleInputChange = (e, section, field) => {
    const { name, value, type, checked } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field || name]: type === 'checkbox' ? checked : value,
      },
    }))
  }

  const handleSiblingChange = (index, field, value) => {
    setFormData((prevState) => {
      const newSiblings = [...prevState.familyInfo.siblings]
      newSiblings[index] = { ...newSiblings[index], [field]: value }
      return {
        ...prevState,
        familyInfo: {
          ...prevState.familyInfo,
          siblings: newSiblings,
        },
      }
    })
  }

  const addSibling = () => {
    setFormData((prevState) => ({
      ...prevState,
      familyInfo: {
        ...prevState.familyInfo,
        siblings: [
          ...prevState.familyInfo.siblings,
          { name: '', grade: '', section: '' },
        ],
      },
    }))
  }

  const removeSibling = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      familyInfo: {
        ...prevState.familyInfo,
        siblings: prevState.familyInfo.siblings.filter((_, i) => i !== index),
      },
    }))
  }

  const resetForm = () => {
    setFormData(initialFormState)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      const response = await axiosInstance.post('admission/submit', formData)
      if (response.status === 201) {
        setShowSuccessModal(true)
        resetForm()
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          'An error occurred while submitting the form. Please try again.'
      )
      setShowErrorModal(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout>
      <Topbar title='New Admission' />
      <div className=' text-white p-6 rounded-lg shadow-lg w-full mt-5'>
        <form onSubmit={handleSubmit} className='space-y-6'>
          {/* Student Information */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>STUDENT INFORMATION</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='studentName'
                  className='block text-sm font-medium'
                >
                  Student's Name:
                </label>
                <input
                  type='text'
                  id='studentName'
                  name='name'
                  value={formData.studentInfo.name}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Name...'
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='prevSchool'
                  className='block text-sm font-medium'
                >
                  Previous School:
                </label>
                <input
                  type='text'
                  id='prevSchool'
                  name='prevSchool'
                  value={formData.studentInfo.prevSchool}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Previous School...'
                />
              </div>
              <div>
                <label htmlFor='grade' className='block text-sm font-medium'>
                  Grade:
                </label>
                <select
                  id='grade'
                  name='grade'
                  value={formData.studentInfo.grade}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  required
                >
                  <option value=''>Select Grade</option>
                  <option value='PG'>PG</option>
                  <option value='Nursery'>Nursery</option>
                  <option value='KG'>KG</option>
                  <option value='Grade 1'>Grade 1</option>
                  <option value='Grade 2'>Grade 2</option>
                  <option value='Grade 3'>Grade 3</option>
                  <option value='Grade 4'>Grade 4</option>
                  <option value='Grade 5'>Grade 5</option>
                  <option value='Grade 6'>Grade 6</option>
                  <option value='Grade 7'>Grade 7</option>
                </select>
              </div>
              <div>
                <label htmlFor='dob' className='block text-sm font-medium'>
                  Date of Birth:
                </label>
                <input
                  type='date'
                  id='dob'
                  name='dob'
                  value={formData.studentInfo.dob}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  required
                />
              </div>
              <div>
                <label htmlFor='gender' className='block text-sm font-medium'>
                  Gender:
                </label>
                <select
                  id='gender'
                  name='gender'
                  value={formData.studentInfo.gender}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  required
                >
                  <option value=''>Select Gender</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor='nationality'
                  className='block text-sm font-medium'
                >
                  Nationality:
                </label>
                <input
                  type='text'
                  id='nationality'
                  name='nationality'
                  value={formData.studentInfo.nationality}
                  onChange={(e) => handleInputChange(e, 'studentInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Nationality...'
                  required
                />
              </div>
            </div>
          </div>

          {/* Father Information */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>FATHER'S PROFILE</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='fatherName'
                  className='block text-sm font-medium'
                >
                  Name:
                </label>
                <input
                  type='text'
                  id='fatherName'
                  name='name'
                  value={formData.fatherInfo.name}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Father Name...'
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='fatherCNIC'
                  className='block text-sm font-medium'
                >
                  CNIC:
                </label>
                <input
                  type='text'
                  id='fatherCNIC'
                  name='cnic'
                  value={formData.fatherInfo.cnic}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter CNIC...'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherCell'
                  className='block text-sm font-medium'
                >
                  Cell#:
                </label>
                <input
                  type='tel'
                  id='fatherCell'
                  name='cell'
                  value={formData.fatherInfo.cell}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Cell#...'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherEmail'
                  className='block text-sm font-medium'
                >
                  Email:
                </label>
                <input
                  type='email'
                  id='fatherEmail'
                  name='email'
                  value={formData.fatherInfo.email}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Fathers Email'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherNationality'
                  className='block text-sm font-medium'
                >
                  Nationality:
                </label>
                <input
                  type='text'
                  id='fatherNationality'
                  name='nationality'
                  value={formData.fatherInfo.nationality}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Father Nationality...'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherOccupation'
                  className='block text-sm font-medium'
                >
                  Occupation:
                </label>
                <input
                  type='text'
                  id='fatherOccupation'
                  name='occupation'
                  value={formData.fatherInfo.occupation}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Father occupation...'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherCompany'
                  className='block text-sm font-medium'
                >
                  Company Name:
                </label>
                <input
                  type='text'
                  id='fatherCompany'
                  name='company'
                  value={formData.fatherInfo.company}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Company Name (if any)'
                />
              </div>
              <div>
                <label
                  htmlFor='fatherEducation'
                  className='block text-sm font-medium'
                >
                  Education:
                </label>
                <input
                  type='text'
                  id='fatherEducation'
                  name='education'
                  value={formData.fatherInfo.education}
                  onChange={(e) => handleInputChange(e, 'fatherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Father Education...'
                />
              </div>
            </div>
          </div>

          {/* Mothers Information */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>MOTHER'S PROFILE</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='motherName'
                  className='block text-sm font-medium'
                >
                  Name:
                </label>
                <input
                  type='text'
                  id='motherName'
                  name='name'
                  value={formData.motherInfo.name}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Mothers Name...'
                  required
                />
              </div>
              <div>
                <label
                  htmlFor='motherOccupation'
                  className='block text-sm font-medium'
                >
                  Occupation:
                </label>
                <input
                  type='text'
                  id='motherOccupation'
                  name='occupation'
                  value={formData.motherInfo.occupation}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Occupation...'
                />
              </div>
              <div>
                <label
                  htmlFor='motherEducation'
                  className='block text-sm font-medium'
                >
                  Education:
                </label>
                <input
                  type='text'
                  id='motherEducation'
                  name='education'
                  value={formData.motherInfo.education}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Mothers Education...'
                />
              </div>
              <div>
                <label
                  htmlFor='motherCompany'
                  className='block text-sm font-medium'
                >
                  Company Name:
                </label>
                <input
                  type='text'
                  id='motherCompany'
                  name='company'
                  value={formData.motherInfo.company}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Company Name (if any)'
                />
              </div>
              <div>
                <label
                  htmlFor='motherCell'
                  className='block text-sm font-medium'
                >
                  Cell#:
                </label>
                <input
                  type='tel'
                  id='motherCell'
                  name='cell'
                  value={formData.motherInfo.cell}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Cell#...'
                />
              </div>
              <div>
                <label
                  htmlFor='motherIsWorking'
                  className='block text-sm font-medium'
                >
                  Is Working:
                </label>
                <input
                  type='checkbox'
                  id='motherIsWorking'
                  name='isWorking'
                  checked={formData.motherInfo.isWorking}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 rounded border-blue-300 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='motherJob'
                  className='block text-sm font-medium'
                >
                  Job:
                </label>
                <input
                  type='text'
                  id='motherJob'
                  name='job'
                  value={formData.motherInfo.job}
                  onChange={(e) => handleInputChange(e, 'motherInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Job...'
                />
              </div>
            </div>
          </div>

          {/* Family Information */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>FAMILY INFORMATION</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label htmlFor='address' className='block text-sm font-medium'>
                  Address:
                </label>
                <textarea
                  id='address'
                  name='address'
                  value={formData.familyInfo.address}
                  onChange={(e) => handleInputChange(e, 'familyInfo')}
                  rows='3'
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter Address...'
                  required
                ></textarea>
              </div>
              <div>
                <label
                  htmlFor='firstLanguage'
                  className='block text-sm font-medium'
                >
                  First Language:
                </label>
                <input
                  type='text'
                  id='firstLanguage'
                  name='firstLanguage'
                  value={formData.familyInfo.firstLanguage}
                  onChange={(e) => handleInputChange(e, 'familyInfo')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Enter First Language...'
                />
              </div>
            </div>
            <div className='mt-4'>
              <label className='block text-sm font-medium'>Siblings:</label>
              {formData.familyInfo.siblings.map((sibling, index) => (
                <div key={index} className='flex gap-2 mt-2'>
                  <input
                    type='text'
                    value={sibling.name}
                    onChange={(e) =>
                      handleSiblingChange(index, 'name', e.target.value)
                    }
                    className='p-2 block w-full rounded-md border-blue-300 text-blue-800'
                    placeholder='Name'
                  />
                  <input
                    type='text'
                    value={sibling.grade}
                    onChange={(e) =>
                      handleSiblingChange(index, 'grade', e.target.value)
                    }
                    className='p-2 block w-full rounded-md border-blue-300 text-blue-800'
                    placeholder='Grade'
                  />
                  <input
                    type='text'
                    value={sibling.section}
                    onChange={(e) =>
                      handleSiblingChange(index, 'section', e.target.value)
                    }
                    className='p-2 block w-full rounded-md border-blue-300 text-blue-800'
                    placeholder='Section'
                  />
                  <button
                    type='button'
                    onClick={() => removeSibling(index)}
                    className='px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600'
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type='button'
                onClick={addSibling}
                className='mt-2 px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700'
              >
                Add Sibling
              </button>
            </div>
          </div>

          {/* Extracurricular Activities */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>
              EXTRACURRICULAR ACTIVITIES
            </h2>
            <div>
              <label className='block text-sm font-medium'>Sports:</label>
              <div className='flex flex-wrap gap-2 mt-2'>
                {[
                  'Football',
                  'Basketball',
                  'Cricket',
                  'Swimming',
                  'Tennis',
                ].map((sport) => (
                  <label key={sport} className='inline-flex items-center'>
                    <input
                      type='checkbox'
                      name='sports'
                      value={sport}
                      checked={formData.extracurricular.sports.includes(sport)}
                      onChange={(e) => {
                        const updatedSports = e.target.checked
                          ? [...formData.extracurricular.sports, sport]
                          : formData.extracurricular.sports.filter(
                              (s) => s !== sport
                            )
                        setFormData((prevState) => ({
                          ...prevState,
                          extracurricular: {
                            ...prevState.extracurricular,
                            sports: updatedSports,
                          },
                        }))
                      }}
                      className='form-checkbox h-5 w-5 text-blue-600'
                    />
                    <span className='ml-2'>{sport}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className='mt-4'>
              <label className='block text-sm font-medium'>Clubs:</label>
              <div className='flex flex-wrap gap-2 mt-2'>
                {['Art', 'Music', 'Drama', 'Science', 'Debate'].map((club) => (
                  <label key={club} className='inline-flex items-center'>
                    <input
                      type='checkbox'
                      name='clubs'
                      value={club}
                      checked={formData.extracurricular.clubs.includes(club)}
                      onChange={(e) => {
                        const updatedClubs = e.target.checked
                          ? [...formData.extracurricular.clubs, club]
                          : formData.extracurricular.clubs.filter(
                              (c) => c !== club
                            )
                        setFormData((prevState) => ({
                          ...prevState,
                          extracurricular: {
                            ...prevState.extracurricular,
                            clubs: updatedClubs,
                          },
                        }))
                      }}
                      className='form-checkbox h-5 w-5 text-blue-600'
                    />
                    <span className='ml-2'>{club}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Medical History */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>MEDICAL HISTORY</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='allergies'
                  className='block text-sm font-medium'
                >
                  Allergies:
                </label>
                <input
                  type='text'
                  id='allergies'
                  name='allergies'
                  value={formData.medicalHistory.allergies}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Any Allergies?'
                />
              </div>
              <div>
                <label htmlFor='asthma' className='block text-sm font-medium'>
                  Asthma:
                </label>
                <input
                  type='checkbox'
                  id='asthma'
                  name='asthma'
                  checked={formData.medicalHistory.asthma}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='speechDefect'
                  className='block text-sm font-medium'
                >
                  Speech Defect:
                </label>
                <input
                  type='checkbox'
                  id='speechDefect'
                  name='speechDefect'
                  checked={formData.medicalHistory.speechDefect}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='visionProblem'
                  className='block text-sm font-medium'
                >
                  Vision Problem:
                </label>
                <input
                  type='checkbox'
                  id='visionProblem'
                  name='visionProblem'
                  checked={formData.medicalHistory.visionProblem}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='hearingProblem'
                  className='block text-sm font-medium'
                >
                  Hearing Problem:
                </label>
                <input
                  type='checkbox'
                  id='hearingProblem'
                  name='hearingProblem'
                  checked={formData.medicalHistory.hearingProblem}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='learningProblem'
                  className='block text-sm font-medium'
                >
                  Learning Problem:
                </label>
                <input
                  type='checkbox'
                  id='learningProblem'
                  name='learningProblem'
                  checked={formData.medicalHistory.learningProblem}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='compulsiveDisorder'
                  className='block text-sm font-medium'
                >
                  Compulsive Disorder:
                </label>
                <input
                  type='checkbox'
                  id='compulsiveDisorder'
                  name='compulsiveDisorder'
                  checked={formData.medicalHistory.compulsiveDisorder}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 h-5 w-5 text-blue-600'
                />
              </div>
              <div>
                <label
                  htmlFor='medication'
                  className='block text-sm font-medium'
                >
                  Medication:
                </label>
                <input
                  type='text'
                  id='medication'
                  name='medication'
                  value={formData.medicalHistory.medication}
                  onChange={(e) => handleInputChange(e, 'medicalHistory')}
                  className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                  placeholder='Any medications?'
                />
              </div>
            </div>
            <div className='mt-4'>
              <label
                htmlFor='otherConditions'
                className='block text-sm font-medium'
              >
                Other Conditions:
              </label>
              <textarea
                id='otherConditions'
                name='otherConditions'
                value={formData.medicalHistory.otherConditions}
                onChange={(e) => handleInputChange(e, 'medicalHistory')}
                rows='3'
                className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                placeholder='Any other medical conditions?'
              ></textarea>
            </div>
          </div>

          {/* Notes */}
          <div className='bg-white text-blue-800 p-4 rounded-md'>
            <h2 className='text-xl font-bold mb-4'>ADDITIONAL NOTES</h2>
            <div>
              <label htmlFor='notes' className='block text-sm font-medium'>
                Notes:
              </label>
              <textarea
                id='notes'
                name='notes'
                value={formData.notes}
                onChange={(e) =>
                  setFormData({ ...formData, notes: e.target.value })
                }
                rows='3'
                className='mt-1 p-2 block w-full rounded-md border-blue-300 text-blue-800'
                placeholder='Any additional notes...'
              ></textarea>
            </div>
          </div>

          <div className='flex justify-end'>
            <button
              type='submit'
              className='px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out'
              disabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <Modal
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          title='Success'
          type='success'
        >
          <p>Admission form submitted successfully!</p>
        </Modal>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <Modal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          title='Error'
          type='error'
        >
          <p>{errorMessage}</p>
        </Modal>
      )}
    </Layout>
  )
}

export default AddStudent
