import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../../../config'
// FOR NEW ADMISSIONS STUDENTS, FIRST TIME FEE

const FeeSlipMaker = ({
  onAddItems,
  dueDate,
  onDueDateChange,
  currentStudent,
}) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [feeInputs, setFeeInputs] = useState({
    regularFee: 11500,
    registrationFee: 5000,
    admissionFee: 15000,
    securityFee: 10000,
    annualSubscription: 7500,
  })
  const [selectedMonths, setSelectedMonths] = useState([])

  const [totalAmount, setTotalAmount] = useState(0)
  const [isPaid, setIsPaid] = useState(true)
  const [recentBarCode, setRecentBarCode] = useState('')
  const navigate = useNavigate()
  const params = useParams()

  const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  useEffect(() => {
    // Set initial due date to 10th of next month and select the next month
    const now = new Date()
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    const initialDueDate = new Date(
      nextMonth.getFullYear(),
      nextMonth.getMonth() + 1,
      10
    )

    onDueDateChange({
      target: { value: initialDueDate.toISOString().split('T')[0] },
    })

    const nextMonthName = monthsList[nextMonth.getMonth()]
    setSelectedMonths([nextMonthName])
  }, [])

  useEffect(() => {
    if (
      ['PG', 'Nursery', 'KG'].includes(currentStudent.studentInfo.gradeName)
    ) {
      setFeeInputs((prev) => ({ ...prev, regularFee: 12500 }))
    } else {
      setFeeInputs((prev) => ({ ...prev, regularFee: 11500 }))
    }
  }, [currentStudent.studentInfo.gradeName])

  const formatSelectedMonths = () => {
    if (selectedMonths.length === 0) return 'No months selected.'
    const sortedMonths = selectedMonths.sort(
      (a, b) => monthsList.indexOf(a) - monthsList.indexOf(b)
    )
    return `${sortedMonths[0]} to ${sortedMonths[sortedMonths.length - 1]}`
  }

  const generateTransactionId = () => {
    const timestamp = Date.now().toString(36)
    const randomStr = Math.random().toString(36).substr(2, 5)
    return `OGS-${timestamp}-${randomStr}`.toUpperCase()
  }

  useQuery(
    'barcode-data',
    async () => {
      const res = await axiosInstance.get(`/fee/barcode`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRecentBarCode(data.barcodeValue)
      },
    }
  )

  useEffect(() => {
    setSelectedItems(
      Object.entries(feeInputs)
        .map(([key, value], index) => ({
          id: index + 1,
          description:
            key.charAt(0).toUpperCase() +
            key
              .slice(1)
              .replace(/([A-Z])/g, ' $1')
              .trim(),
          amount: key === 'regularFee' ? value * selectedMonths.length : value,
          isChecked: true,
        }))
        .filter((item) => item.amount > 0 || item.description === 'Regular Fee')
    )
  }, [feeInputs, selectedMonths.length])

  const updateDueDateBasedOnSelection = () => {
    if (selectedMonths.length > 0) {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const lastSelectedMonthIndex =
        sortedSelectedMonthIndices[sortedSelectedMonthIndices.length - 1]

      const dueDate = new Date()
      dueDate.setMonth(lastSelectedMonthIndex + 1) // +2 because we want the next month after the last selected
      dueDate.setDate(10)

      if (dueDate.getMonth() === 0) {
        dueDate.setFullYear(dueDate.getFullYear() + 1)
      }

      const dueDateString = dueDate.toISOString().split('T')[0]
      onDueDateChange({ target: { value: dueDateString } })
    }
  }

  const handleMonthChange = (month) => {
    if (selectedMonths.includes(month)) {
      setSelectedMonths(selectedMonths.filter((m) => m !== month))
    } else {
      const monthIndex = monthsList.indexOf(month)
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)

      if (
        sortedSelectedMonthIndices.length === 0 ||
        Math.abs(
          monthIndex -
            sortedSelectedMonthIndices[sortedSelectedMonthIndices.length - 1]
        ) <= 1 ||
        Math.abs(monthIndex - sortedSelectedMonthIndices[0]) <= 1
      ) {
        setSelectedMonths(
          [...selectedMonths, month].sort(
            (a, b) => monthsList.indexOf(a) - monthsList.indexOf(b)
          )
        )
      }
    }
  }

  useEffect(() => {
    const total = selectedItems.reduce((acc, item) => {
      return item.isChecked ? acc + item.amount : acc
    }, 0)
    setTotalAmount(total)
  }, [selectedItems])

  const handleAddItems = async () => {
    const items = selectedItems
      .filter((item) => item.isChecked)
      .map((item) => ({
        description: item.description,
        amount:
          item.description === 'Regular Fee'
            ? feeInputs.regularFee
            : item.amount,
      }))

    const monthRange = formatSelectedMonths()
    const [fromMonth, toMonth] = monthRange.split(' to ')

    // Create Date objects for the first day of the selected months
    const fromDate = new Date(
      new Date().getFullYear(),
      monthsList.indexOf(fromMonth),
      1
    )
    const toDate = new Date(
      new Date().getFullYear(),
      monthsList.indexOf(toMonth) + 1,
      0
    ) // Last day of the month

    const transactionId = generateTransactionId()

    const data = {
      admissionId: params.id,
      items,
      period: {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      },
      dueDate: new Date(dueDate).toISOString(),
      totalAmount,
      status: isPaid ? 'Paid' : 'Pending',
      paymentDate: isPaid ? new Date().toISOString() : null,
      transactionId: transactionId,
    }

    try {
      const response = await axiosInstance.post('fee/feeslips/', data)
      console.log('POST request successful:', response.data)
      navigate(`/students/all-admissions/fee-challan/${params.id}`)
    } catch (error) {
      console.error(
        'POST request failed:',
        error.response ? error.response.data : error
      )
    }
  }

  const handleItemCheck = (id) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    )
  }

  const handleFeeInputChange = (feeType, value) => {
    setFeeInputs((prev) => ({
      ...prev,
      [feeType]: Number(value),
    }))
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='bg-white shadow-lg rounded-lg overflow-hidden'>
        <div className='bg-blue-600 text-white p-4'>
          <h2 className='text-2xl font-bold'>Fee Slip Generator</h2>
        </div>
        <div className='p-6'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>
                Due Date
              </label>
              <input
                type='date'
                value={dueDate}
                onChange={(e) => onDueDateChange(e)}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
            {Object.entries(feeInputs).map(([key, value]) => (
              <div key={key}>
                <label className='block text-sm font-medium text-gray-700 mb-2'>
                  {key.charAt(0).toUpperCase() +
                    key
                      .slice(1)
                      .replace(/([A-Z])/g, ' $1')
                      .trim()}
                </label>
                <input
                  type='number'
                  value={value}
                  onChange={(e) => handleFeeInputChange(key, e.target.value)}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                />
              </div>
            ))}
          </div>

          <div className='mb-6'>
            <h3 className='text-lg font-medium text-gray-900 mb-2'>
              Select Months
            </h3>
            <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2'>
              {monthsList.map((month) => (
                <button
                  key={month}
                  onClick={() => handleMonthChange(month)}
                  className={`px-2 py-1 text-sm rounded-md ${
                    selectedMonths.includes(month)
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {month}
                </button>
              ))}
            </div>
          </div>

          <div className='mb-6'>
            <h3 className='text-lg font-medium text-gray-900 mb-2'>
              Selected Months
            </h3>
            <p className='text-gray-600'>{formatSelectedMonths()}</p>
          </div>

          <div className='flex items-center mb-6'>
            <input
              type='checkbox'
              checked={isPaid}
              onChange={(e) => setIsPaid(e.target.checked)}
              className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
            />
            <span className='ml-2 text-gray-700'>Paid</span>
          </div>

          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Description
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Amount (Rs.)
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Include
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {selectedItems.map((item) => (
                  <tr key={item.id}>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                      {item.description}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right'>
                      {item.amount.toLocaleString()}
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-center'>
                      <input
                        type='checkbox'
                        checked={item.isChecked}
                        onChange={() => handleItemCheck(item.id)}
                        className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='bg-gray-50'>
                  <td
                    colSpan='2'
                    className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'
                  >
                    Total
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right'>
                    {totalAmount.toLocaleString()}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className='mt-6 text-center'>
            <button
              onClick={handleAddItems}
              className='inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              <svg
                className='h-5 w-5 mr-2'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z'
                  clipRule='evenodd'
                />
              </svg>
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeeSlipMaker
