import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'

const subjects = [
  { id: 1, name: 'Mathematics', numofTeachers: 2, numOfStudents: 28 },
  { id: 2, name: 'English Language Arts', numofTeachers: 2, numOfStudents: 80 },
  { id: 3, name: 'Science', numofTeachers: 1, numOfStudents: 71 },
  { id: 4, name: 'Social Studies', numofTeachers: 2, numOfStudents: 80 },
  { id: 5, name: 'Physical Education', numofTeachers: 2, numOfStudents: 84 },
  { id: 6, name: 'Health', numofTeachers: 2, numOfStudents: 46 },
  { id: 7, name: 'Art', numofTeachers: 2, numOfStudents: 91 },
  { id: 8, name: 'Music', numofTeachers: 2, numOfStudents: 55 },
  { id: 9, name: 'Computer Science', numofTeachers: 2, numOfStudents: 71 },
]

const SubjectsMain = () => {
  const [showForm, setShowForm] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [newSubject, setNewSubject] = useState({
    name: '',
    numofTeachers: '',
    numOfStudents: '',
    description: '',
    curriculum: '',
    gradeLevel: '',
  })

  const filteredSubjects = subjects.filter((subject) =>
    subject.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewSubject({ ...newSubject, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Here you would typically send the data to your backend
    console.log('New subject submitted:', newSubject)
    // Reset form and close
    setNewSubject({
      name: '',
      numofTeachers: '',
      numOfStudents: '',
      description: '',
      curriculum: '',
      gradeLevel: '',
    })
    setShowForm(false)
  }

  return (
    <div className='min-h-screen p-8'>
      <div className='max-w-7xl mx-auto'>
        <div className='flex justify-between items-center mb-8'>
          <h1 className='text-3xl font-bold text-[#1bc526]'>
            Subjects Dashboard
          </h1>
          <button
            onClick={() => setShowForm(!showForm)}
            className='bg-[#1edb2b] hover:bg-[#1bc526] text-white font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#1edb2b] focus:ring-opacity-50'
          >
            {showForm ? 'Close Form' : 'Add Subject'}
          </button>
        </div>

        <div className='mb-8'>
          <div className='flex items-center bg-white rounded-full shadow-md p-2 max-w-md mx-auto'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 text-gray-400 ml-3 mr-2'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
            <input
              type='text'
              placeholder='Search Subjects Here...'
              className='w-full outline-none text-gray-700'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <motion.div
          className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {filteredSubjects.map((subject) => (
            <motion.div
              key={subject.id}
              className='bg-white rounded-[40px] shadow-lg'
              whileHover={{ scale: 1.03 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <div className='bg-[#040290] h-40 flex items-center justify-center'>
                <span className='text-white text-8xl font-bold opacity-80'>
                  {subject.name.charAt(0)}
                </span>
              </div>
              <div className='p-4 bg-[#1edb2b] relative'>
                <div className='flex justify-between items-center mb-2 text-white'>
                  <span className='text-sm'>
                    <strong className='font-bold'>S:</strong>{' '}
                    {subject.numOfStudents}
                  </span>
                  <span className='text-sm'>
                    <strong className='font-bold'>T:</strong>{' '}
                    {subject.numofTeachers}
                  </span>
                </div>
                <div className='bg-white text-gray-800 text-center py-3 px-4 rounded-full text-sm font-semibold absolute -bottom-5 left-4 right-4 shadow-md'>
                  {subject.name}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <AnimatePresence>
          {showForm && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className='bg-white p-8 rounded-3xl shadow-2xl max-w-md w-full'
              >
                <h2 className='text-2xl font-bold mb-6 text-gray-800'>
                  Add New Subject
                </h2>
                <form onSubmit={handleSubmit} className='space-y-4'>
                  <div>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Subject Name
                    </label>
                    <input
                      type='text'
                      id='name'
                      name='name'
                      value={newSubject.name}
                      onChange={handleInputChange}
                      required
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='numofTeachers'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Number of Teachers
                    </label>
                    <input
                      type='number'
                      id='numofTeachers'
                      name='numofTeachers'
                      value={newSubject.numofTeachers}
                      onChange={handleInputChange}
                      required
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='numOfStudents'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Number of Students
                    </label>
                    <input
                      type='number'
                      id='numOfStudents'
                      name='numOfStudents'
                      value={newSubject.numOfStudents}
                      onChange={handleInputChange}
                      required
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='description'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Description
                    </label>
                    <textarea
                      id='description'
                      name='description'
                      value={newSubject.description}
                      onChange={handleInputChange}
                      rows='3'
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    ></textarea>
                  </div>
                  <div>
                    <label
                      htmlFor='curriculum'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Curriculum
                    </label>
                    <input
                      type='text'
                      id='curriculum'
                      name='curriculum'
                      value={newSubject.curriculum}
                      onChange={handleInputChange}
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='gradeLevel'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Grade Level
                    </label>
                    <input
                      type='text'
                      id='gradeLevel'
                      name='gradeLevel'
                      value={newSubject.gradeLevel}
                      onChange={handleInputChange}
                      className='mt-1 block w-full rounded-md border-gray-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    />
                  </div>
                  <div className='flex justify-end space-x-3'>
                    <button
                      type='button'
                      onClick={() => setShowForm(false)}
                      className='bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='bg-[#1edb2b] hover:bg-[#1bc526] text-white font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#1edb2b] focus:ring-opacity-50'
                    >
                      Add Subject
                    </button>
                  </div>
                </form>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default SubjectsMain
