import { Add, Announcement } from '@mui/icons-material'
import React from 'react'
import { dummyData } from '../../utils/dummyData'

const AnnouncementsCard = () => (
  <div className='bg-gradient-to-br from-green-800 to-green-900 rounded-lg p-6 shadow-lg'>
    <div className='flex justify-between items-center mb-4'>
      <h2 className='text-xl font-bold text-white flex items-center'>
        <Announcement className='mr-2' />
        Announcements
      </h2>
      <button className='bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-full transition duration-300 flex items-center'>
        <Add className='mr-1' />
        Add
      </button>
    </div>
    <div className='space-y-4 max-h-80 overflow-y-auto'>
      {dummyData.announcements.map((announcement, index) => (
        <div
          key={index}
          className='bg-white/10 rounded-lg p-4 hover:bg-white/20 transition duration-300'
        >
          <h3 className='font-semibold text-green-300'>{announcement.title}</h3>
          <p className='text-sm text-white mt-1'>{announcement.content}</p>
          <p className='text-xs text-green-400 mt-2'>
            Posted on: 15 Ramadan, 1442
          </p>
        </div>
      ))}
    </div>
  </div>
)

export default AnnouncementsCard
