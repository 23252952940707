import { ArrowBack } from '@mui/icons-material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Topbar = ({ title }) => {
  const { currentUser } = useSelector((state) => state.user)
  const navigate = useNavigate()

  return (
    <div className='flex items-center justify-between w-full text-white'>
      <div className='flex items-center space-x-4'>
        <button
          onClick={() => navigate(-1)}
          className='p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors duration-200'
        >
          <ArrowBack className='text-2xl' />
        </button>
        <h1 className='text-2xl md:text-3xl font-bold tracking-wider'>
          {title}
        </h1>
      </div>
      <div className='flex items-center space-x-4'>
        <div className='text-right hidden sm:block'>
          <p className='font-semibold text-sm md:text-base'>
            {currentUser
              ? `${currentUser.firstName} ${currentUser.lastName}`
              : 'User...'}
          </p>
          <p className='text-xs md:text-sm opacity-75'>
            {currentUser ? currentUser.email : 'Role...'}
          </p>
        </div>
        <img
          src={
            currentUser?.profileImage ||
            'https://w7.pngwing.com/pngs/490/157/png-transparent-male-avatar-boy-face-man-user-flat-classy-users-icon.png'
          }
          alt='User Profile'
          className='w-10 h-10 md:w-12 md:h-12 rounded-full object-cover border-2 border-white'
        />
      </div>
    </div>
  )
}

export default Topbar
