import { ArrowLeft, Save } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Topbar from '../../components/Layout/Topbar'
import { axiosInstance } from '../../config'
import Layout from '../../Layout'

const EditTeacher = () => {
  const [teacherData, setTeacherData] = useState({
    fname: '',
    lname: '',
    gender: '',
    cell: '',
    scEmail: '',
    gmail: '',
    address: '',
    department: '',
    others: '',
  })
  const [isLoading, setIsLoading] = useState(true)
  const params = useParams()
  const navigate = useNavigate()

  const { status } = useQuery(
    'edit-teacher',
    async () => {
      const res = await axiosInstance.get(`/teacher/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setTeacherData(data)
        setIsLoading(false)
      },
    }
  )

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setTeacherData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await axiosInstance.put(`teacher/${params.id}`, teacherData)
      navigate(`/teachers/view/${params.id}`)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className='fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center'>
        <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
      </div>
    )
  }

  return (
    <Layout>
      <Topbar title='Edit Teacher' />
      <div className='min-h-screen py-6 flex flex-col justify-center sm:py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full'>
          <div className='relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20'>
            <form onSubmit={handleSubmit} className='w-full max-w-4xl mx-auto'>
              <h1 className='text-2xl font-semibold text-center mb-6'>
                Edit Teacher Information
              </h1>

              <InputField
                label='First Name'
                name='fname'
                value={teacherData.fname}
                onChange={handleInputChange}
              />
              <InputField
                label='Last Name'
                name='lname'
                value={teacherData.lname}
                onChange={handleInputChange}
              />
              <SelectField
                label='Gender'
                name='gender'
                value={teacherData.gender}
                onChange={handleInputChange}
                options={['Male', 'Female', 'Prefer Not To Say']}
              />
              <InputField
                label='Cell#'
                name='cell'
                value={teacherData.cell}
                onChange={handleInputChange}
              />
              <InputField
                label='School Email'
                name='scEmail'
                value={teacherData.scEmail}
                onChange={handleInputChange}
                readOnly
              />
              <InputField
                label='Personal Email'
                name='gmail'
                value={teacherData.gmail}
                onChange={handleInputChange}
              />
              <InputField
                label='Address'
                name='address'
                value={teacherData.address}
                onChange={handleInputChange}
              />
              <InputField
                label='Department'
                name='department'
                value={teacherData.department}
                onChange={handleInputChange}
              />
              <TextAreaField
                label='Other Information'
                name='others'
                value={teacherData.others}
                onChange={handleInputChange}
              />

              <div className='mt-8 flex flex-wrap justify-center gap-4'>
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className='flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors'
                >
                  <ArrowLeft className='mr-2' size={18} />
                  Back
                </button>
                <button
                  type='submit'
                  className='flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors'
                >
                  <Save className='mr-2' size={18} />
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const InputField = ({ label, name, value, onChange, readOnly = false }) => (
  <div className='mb-4'>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type='text'
      name={name}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      className='mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500'
    />
  </div>
)

const SelectField = ({ label, name, value, onChange, options }) => (
  <div className='mb-4'>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      className='mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500'
    >
      <option value=''>Choose</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
)

const TextAreaField = ({ label, name, value, onChange }) => (
  <div className='mb-4'>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      rows='3'
      className='mt-1 p-2 w-full border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500'
    ></textarea>
  </div>
)

export default EditTeacher
