import {
  BarChart2,
  DollarSign,
  Download,
  Eye,
  Menu,
  Search,
  TrendingUp,
  UserCheck,
} from 'lucide-react'
import React, { useState } from 'react'
import Topbar from '../components/Layout/Topbar'
import Layout from '../Layout'

const ReportsPage = () => {
  const [activeTab, setActiveTab] = useState('academic')
  const [searchTerm, setSearchTerm] = useState('')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const reports = {
    academic: [
      {
        id: 1,
        title: 'End of Term Report',
        date: '2024-06-30',
        downloads: 145,
      },
      {
        id: 2,
        title: 'Mid-Term Progress Report',
        date: '2024-04-15',
        downloads: 98,
      },
      {
        id: 3,
        title: 'Annual Academic Summary',
        date: '2024-12-20',
        downloads: 210,
      },
      {
        id: 4,
        title: 'Student Performance Analysis',
        date: '2024-05-10',
        downloads: 76,
      },
    ],
    financial: [
      {
        id: 1,
        title: 'Tuition Fee Collection',
        date: '2024-07-05',
        downloads: 56,
      },
      { id: 2, title: 'Budget Allocation', date: '2024-01-10', downloads: 89 },
      { id: 3, title: 'Expense Report', date: '2024-12-31', downloads: 123 },
      { id: 4, title: 'Financial Forecast', date: '2024-09-15', downloads: 67 },
    ],
    attendance: [
      {
        id: 1,
        title: 'Monthly Attendance Summary',
        date: '2024-06-30',
        downloads: 178,
      },
      {
        id: 2,
        title: 'Chronic Absenteeism Report',
        date: '2024-05-15',
        downloads: 92,
      },
      {
        id: 3,
        title: 'Teacher Attendance Log',
        date: '2024-06-30',
        downloads: 45,
      },
      {
        id: 4,
        title: 'Attendance Trend Analysis',
        date: '2024-08-20',
        downloads: 134,
      },
    ],
  }

  const filteredReports = reports[activeTab].filter((report) =>
    report.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const tabIcons = {
    academic: <BarChart2 className='w-4 h-4' />,
    financial: <DollarSign className='w-4 h-4' />,
    attendance: <UserCheck className='w-4 h-4' />,
  }

  const summaryCards = [
    {
      title: 'Total Reports',
      icon: <BarChart2 className='w-4 h-4' />,
      value: Object.values(reports).flat().length,
      trend: '+20% from last month',
    },
    {
      title: 'Total Insights',
      icon: <Download className='w-4 h-4' />,
      value: Object.values(reports)
        .flat()
        .reduce((sum, report) => sum + report.downloads, 0),
      trend: '+15% from last month',
    },
    {
      title: 'Most Popular Report',
      icon: <TrendingUp className='w-4 h-4' />,
      value: 'Annual Academic Summary',
      trend: '210 downloads',
    },
  ]

  return (
    <Layout>
      <Topbar title='Reports' />
      <div className='min-h-screen  text-gray-900 p-4 sm:p-6 lg:p-8'>
        <div className='max-w-7xl mx-auto space-y-6'>
          <h1 className='text-3xl sm:text-4xl font-bold text-green-400'>
            School Reports Dashboard
          </h1>

          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
            {summaryCards.map((card, index) => (
              <div key={index} className='bg-white p-6 rounded-lg shadow-sm'>
                <div className='flex justify-between items-center mb-4'>
                  <h3 className='text-sm font-medium text-gray-500'>
                    {card.title}
                  </h3>
                  {card.icon}
                </div>
                <div className='text-2xl font-bold mb-1'>{card.value}</div>
                <p className='text-xs text-gray-500'>{card.trend}</p>
              </div>
            ))}
          </div>

          <div className='bg-white rounded-lg shadow-sm p-6'>
            <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 mb-6'>
              <div className='flex flex-wrap gap-2'>
                <button
                  className='lg:hidden px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <Menu className='w-4 h-4 mr-2 inline' />
                  Menu
                </button>
                <div
                  className={`flex flex-col lg:flex-row gap-2 w-full lg:w-auto ${
                    mobileMenuOpen ? 'block' : 'hidden lg:flex'
                  }`}
                >
                  {Object.keys(reports).map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-3 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                        activeTab === tab
                          ? 'bg-blue-600 text-white'
                          : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50'
                      }`}
                    >
                      {tabIcons[tab]}
                      <span className='ml-2'>
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
              <div className='w-full sm:w-64 relative'>
                <input
                  type='text'
                  placeholder='Search reports...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className='w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
                />
                <Search className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4' />
              </div>
            </div>

            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Report Title
                    </th>
                    <th className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Date
                    </th>
                    <th className='px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Insights
                    </th>
                    <th className='px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {filteredReports.map((report) => (
                    <tr key={report.id} className='hover:bg-gray-50'>
                      <td className='px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                        {report.title}
                      </td>
                      <td className='px-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {report.date}
                      </td>
                      <td className='px-4 py-4 whitespace-nowrap text-sm text-gray-500'>
                        {report.downloads}
                      </td>
                      <td className='px-4 py-4 whitespace-nowrap text-right text-sm font-medium'>
                        <button className='text-blue-600 hover:text-blue-900 mr-2'>
                          <Eye className='w-4 h-4 mr-1 inline' />
                          <span className='hidden sm:inline'>View</span>
                        </button>
                        <button className='text-blue-600 hover:text-blue-900'>
                          <Download className='w-4 h-4 mr-1 inline' />
                          <span className='hidden sm:inline'>Insights</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ReportsPage
