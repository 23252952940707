import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ClassIcon from '@mui/icons-material/Class'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PersonIcon from '@mui/icons-material/Person'
import SchoolIcon from '@mui/icons-material/School'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { axiosInstance } from '../../config'
import { dummyData } from '../../utils/dummyData'
import InfoCard from './InfoCard'

const InfoCardGrid = () => {
  const [totalStudents, setTotalStudents] = useState(0)

  useQuery(
    'students-total-count',
    async () => {
      const res = await axiosInstance.get(`/student/counts`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setTotalStudents(data.count || 0)
      },
    }
  )
  return (
    <div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-6'>
        <InfoCard
          icon={<SchoolIcon />}
          title='Students'
          value={totalStudents}
        />
        <InfoCard
          icon={<PersonIcon />}
          title='Teachers'
          value={dummyData.totalTeachers}
        />
        <InfoCard
          icon={<ClassIcon />}
          title='Classes'
          value={dummyData.totalClasses}
        />
        <InfoCard
          icon={<DirectionsBusIcon />}
          title='Buses'
          value={dummyData.totalBuses}
        />
        <InfoCard
          icon={<HelpOutlineIcon />}
          title='Enquiries'
          value={dummyData.totalEnquiries}
        />
        <InfoCard
          icon={<AttachMoneyIcon />}
          title="Today's Fees"
          value={dummyData.todayFees}
        />
      </div>
    </div>
  )
}

export default InfoCardGrid
