import React from 'react'
import { useSelector } from 'react-redux'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom'
import Activity from './pages/Activity'
import Chat from './pages/Chat'
import Class from './pages/Class'
import Dashboard from './pages/Dashboard'
import Event from './pages/Event'
import Fee from './pages/Fee/Fee'
import Months from './pages/Fee/Months'
import Finance from './pages/Finance'
import Login from './pages/Login'
import Publish from './pages/Publish'
import ReportsPage from './pages/ReportsPage'
import AddStudent from './pages/Students/Admission/AddStudent'
import EditStudent from './pages/Students/Admission/EditStudent'
import FeeSlip from './pages/Students/Admission/Fee/FeeSlip'
import FeeSlipDetails from './pages/Students/Admission/Fee/FeeSlipDetails'
import Invoice from './pages/Students/Admission/Fee/Invoice'
import ViewDeletedInvoices from './pages/Students/Admission/Fee/ViewDeletedInvoices'
import ViewFeeHistory from './pages/Students/Admission/Fee/ViewFeeHistory'
import StudentAdmissions from './pages/Students/Admission/StudentAdmissions'
import ViewDeletedStudents from './pages/Students/Admission/ViewDeletedStudents'
import ViewStudent from './pages/Students/Admission/ViewStudent'
import EditEnrolled from './pages/Students/Enrolled/EditEnrolled'
import Enrolled from './pages/Students/Enrolled/Enrolled'
import EnrolledFeeHistory from './pages/Students/Enrolled/Fee/EnrolledFeeHistory'
import FeePage from './pages/Students/Enrolled/Fee/FeePage'
import ViewDeletedEnrolled from './pages/Students/Enrolled/ViewDeletedEnrolled'
import ViewEnrolled from './pages/Students/Enrolled/ViewEnrolled'
import Students from './pages/Students/Students'
import CamperInvoice from './pages/Students/SummerCamp/CamperInvoice'
import EditCamper from './pages/Students/SummerCamp/EditCamper'
import NewCamper from './pages/Students/SummerCamp/NewCamper'
import SummerCamp from './pages/Students/SummerCamp/SummerCamp'
import ViewCamper from './pages/Students/SummerCamp/ViewCamper'
import Subjects from './pages/Subjects'
import Teachers from './pages/Teacher'
import AddTeacher from './pages/Teacher/AddTeacher'
import EditTeacher from './pages/Teacher/EditTeacher'
import ViewTeacher from './pages/Teacher/ViewTeacher'
import User from './pages/User'

const App = () => {
  const { currentUser } = useSelector((state) => state.user)

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to='/login' replace />
    }
    return children
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/login'
          element={
            currentUser ? <Navigate replace to='/dashboard' /> : <Login />
          }
        />
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <Navigate to='/dashboard' />
            </ProtectedRoute>
          }
        />
        <Route
          path='dashboard'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path='students'
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<Students />} />
          <Route path='fee-challan'>
            <Route index element={<FeeSlip />} />
            <Route path='recycle/:id' element={<ViewDeletedInvoices />} />
            <Route path=':id' element={<ViewFeeHistory />} />
            <Route path='new/:id' element={<FeeSlipDetails />} />
            <Route path='invoice/:id' element={<Invoice />} />
          </Route>
          <Route path='all-admissions' element={<Outlet />}>
            <Route index element={<StudentAdmissions />} />
            <Route path='new' element={<AddStudent />} />
            <Route path='recent' element={<ViewDeletedStudents />} />
            <Route path='view/:id' element={<ViewStudent />} />
            <Route path='edit/:id' element={<EditStudent />} />
          </Route>
          <Route path='enrolled' element={<Outlet />}>
            <Route index element={<Enrolled />} />
            <Route path='fee-history/:id' element={<EnrolledFeeHistory />} />
            <Route path='edit/:id' element={<EditEnrolled />} />
            <Route path='view/:id' element={<ViewEnrolled />} />
            <Route path='recent' element={<ViewDeletedEnrolled />} />
            <Route path='fee-maker/:id' element={<FeePage />} />
            <Route path='invoice/:id' element={<Invoice />} />
          </Route>
          <Route path='summer-camp' element={<Outlet />}>
            <Route index element={<SummerCamp />} />
            <Route path='view/:id' element={<ViewCamper />} />
            <Route path='new' element={<NewCamper />} />
            <Route path='invoice/:id' element={<CamperInvoice />} />
            <Route path='edit/:id' element={<EditCamper />} />
          </Route>
          <Route path='fee'>
            <Route index element={<Months />} />
            <Route path='fee-table' element={<Fee />} />
          </Route>
        </Route>
        <Route
          path='users'
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path='class'
          element={
            <ProtectedRoute>
              <Class />
            </ProtectedRoute>
          }
        />
        <Route
          path='subjects'
          element={
            <ProtectedRoute>
              <Subjects />
            </ProtectedRoute>
          }
        />
        <Route
          path='teachers'
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<Teachers />} />
          <Route path='add' element={<AddTeacher />} />
          <Route path='view/:id' element={<ViewTeacher />} />
          <Route path='edit/:id' element={<EditTeacher />} />
        </Route>
        <Route
          path='events'
          element={
            <ProtectedRoute>
              <Event />
            </ProtectedRoute>
          }
        />
        <Route
          path='reports'
          element={
            <ProtectedRoute>
              <ReportsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='publish'
          element={
            <ProtectedRoute>
              <Publish />
            </ProtectedRoute>
          }
        />
        <Route
          path='finance'
          element={
            <ProtectedRoute>
              <Finance />
            </ProtectedRoute>
          }
        />
        <Route
          path='chats'
          element={
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path='activity'
          element={
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          }
        />
        <Route path='*' element={<Navigate replace to='/login' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
