import { Add, Download, Visibility } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import Loader from '../../../../Loader/Loader'
import { axiosInstance } from '../../../../config'

// Styled components (unchanged)
const Wrap = styled.div`
  height: 100%;
  width: 95%;
  border-radius: 10px;
`

const GreenBtn = styled.button`
  background-color: #058d19;
  padding: 0.5rem 0.8rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
`

const RedBtn = styled(GreenBtn)`
  background-color: yellow;
  color: #000000;
  margin-right: 15px;
`

const GenBtn = styled(RedBtn)`
  background-color: #06f7e7;
  color: #000000;
`

const WrapBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BtnSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #666;
`

const formatDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-GB')
}

const getShortMonth = (date) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return months[new Date(date).getMonth()]
}

const formatPeriod = (from, to) => {
  const fromMonth = getShortMonth(from)
  const toMonth = getShortMonth(to)
  return `${fromMonth} to ${toMonth}`
}

export default function HistoryDataTable({ currentStudent }) {
  const [statusFilter] = useState('All')
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, status } = useQuery(
    ['fee-history-individual', id],
    async () => {
      const res = await axiosInstance.get(`/fee/feeslips/${id}`)
      return res.data
    }
  )

  const handleGenerateFeeSlip = (event, feeSlipId) => {
    event.stopPropagation()
    navigate(`/students/fee-challan/invoice/${feeSlipId}`)
  }

  const handleDeleteInvoice = async (event, feeSlipId) => {
    event.stopPropagation()
    try {
      await axiosInstance.put(`/fee/invoice/${feeSlipId}/delete`)
      queryClient.invalidateQueries(['fee-history-individual', id])
    } catch (err) {
      console.error('Error deleting invoice:', err)
    }
  }

  const columns = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 50 },
      { field: 'paymentDate', headerName: 'Payment Date', width: 130 },
      {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: (params) => (
          <span
            style={{
              color: params.row.status === 'Paid' ? 'blue' : 'white',
              backgroundColor:
                params.row.status === 'Paid' ? '#060dd039' : 'black',
              padding: '0.5rem',
              borderRadius: '10px',
            }}
          >
            {params.row.status}
          </span>
        ),
      },
      { field: 'period', headerName: 'Period', width: 150 },
      { field: 'totalAmount', headerName: 'Total Amount', width: 130 },
      { field: 'dueDate', headerName: 'Due Date', width: 150 },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        renderCell: (params) => (
          <button
            onClick={(event) => handleGenerateFeeSlip(event, params.row._id)}
          >
            View
          </button>
        ),
      },
      {
        field: 'Remove',
        headerName: 'Delete',
        width: 150,
        renderCell: (params) => (
          <button
            onClick={(event) => handleDeleteInvoice(event, params.row._id)}
          >
            Delete
          </button>
        ),
      },
    ],
    []
  )

  const rows = useMemo(() => {
    if (!data) return []
    return data
      .filter((item) => statusFilter === 'All' || item.status === statusFilter)
      .map((item, index) => ({
        id: index + 1,
        _id: item._id,
        paymentDate: item.paymentDate ? formatDate(item.paymentDate) : 'N/A',
        period: formatPeriod(item.period.from, item.period.to),
        status: item.status,
        totalAmount: item.totalAmount,
        dueDate: formatDate(item.dueDate),
      }))
  }, [data, statusFilter])

  const handleExportToExcel = () => {
    const mappedRows = rows.map(({ id, _id, ...rest }) => rest)
    const ws = XLSX.utils.json_to_sheet(mappedRows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Fee History')
    const currentDate = new Date().toISOString().split('T')[0]
    XLSX.writeFile(wb, `fee_history_${currentDate}.xlsx`)
  }

  return (
    <Wrap>
      <WrapBtn>
        <BtnSet>
          <Link to={`/students/fee-challan/new/${id}`}>
            <GenBtn>
              <Add style={{ marginRight: '5px' }} />
              Create Fee
            </GenBtn>
          </Link>
          <Link to={`/students/all-admissions/fee-challan/recycle/${id}`}>
            <RedBtn>
              <Visibility style={{ marginRight: '5px' }} /> View Deleted
              Invoices
            </RedBtn>
          </Link>
          <GreenBtn onClick={handleExportToExcel}>
            <Download style={{ marginRight: '5px' }} /> Save to Excel
          </GreenBtn>
        </BtnSet>
      </WrapBtn>

      {status === 'loading' ? (
        <Loader msg='Loading Invoices...' />
      ) : rows.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          onRowClick={(param) =>
            navigate(`/students/all-admissions/invoice/${param.row._id}`)
          }
          style={{
            borderRadius: '10px',
            fontSize: '0.9rem',
            backgroundColor: 'white',
            padding: '0 1rem',
          }}
        />
      ) : (
        <NoDataMessage>No fee slips yet</NoDataMessage>
      )}
    </Wrap>
  )
}
