// FeeSlipDetailsComponent.js
import { CloudDownload } from '@mui/icons-material'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'
import FeeSlipMaker from '../../../../components/Students/Admission/Fee/FeeSlipMaker'
import FeeMaker from '../../../../components/Students/Enrolled/Fee/FeeMaker'
import { axiosInstance } from './../../../../config'

const Row = styled.div`
  position: relative;
  height: 100vh;
  background-color: #ffffff;
  min-width: 1200px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

const Wrap = styled.div`
  display: flex;
`

const WrapBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 1rem 2rem 2rem 0rem;
  border-radius: 10px;
  border: 1px solid white;
`
const BarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
`
const BarRight = styled(BarLeft)`
  justify-content: flex-end;
  padding: 2rem;
`
const TextBig = styled.p`
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
`

const BarContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  flex-direction: column;
`
const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`
const Column = styled.div`
  float: left;
  width: 31%;
  padding: 10px 2rem;

  border-right: 1px dotted #000;
  height: 100%;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  font-size: 0.8em;
`

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
`

const TextBox = styled.strong`
  justify-content: center;
  padding: 0.5rem 0;
`

const DateWrap = styled.div`
  display: flex;
  padding: 1rem 0;
  font-size: 0.8em;
`

const FloatRight = styled.div`
  display: flex;
  flex-direction: column;
  float: right;
  justify-content: flex-end;
  font-size: 0.8em;
`

const Table = styled.table`
  margin-top: 10px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
`

const TableRow = styled.tr`
  background-color: #fff;
  border: 1px solid #000;
  padding: 0.35em;
  font-size: 0.9rem;
`

const TableHeader = styled.th`
  padding: 0.625em;
  border: 1px solid #000;
`

const TableCell = styled.td`
  padding: 0.625em;
  border: 1px solid #000;
  font-size: 0.7em;
  letter-spacing: 2px;
`

const HorizontalRule = styled.hr`
  border-top: 1px solid #000;
`

const DownloadButton = styled.button`
  background-color: #026806;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
`
const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  @media print {
    display: none;
  }
`

const FeeSlipDetails = () => {
  const { id } = useParams()
  const [items, setItems] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [totalAmount, setTotalAmount] = useState(0)
  const [currentStudent, setCurrentStudent] = useState(null)

  const { data: studentData, isLoading } = useQuery(
    ['specific-student', id],
    async () => {
      const res = await axiosInstance.get(`/admission/${id}`)
      return res.data
    }
  )

  useEffect(() => {
    if (studentData) {
      setCurrentStudent(studentData)
    }
  }, [studentData])

  useEffect(() => {
    const total = items.reduce(
      (acc, item) => acc + parseFloat(item.amount.replace(/,/g, '')),
      0
    )
    setTotalAmount(total)
  }, [items])

  const handleDueDateChange = (e) => setDueDate(e.target.value)

  const addItemsToSlip = (newItems) => setItems(newItems)

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return `${('0' + date.getDate()).slice(-2)}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`
  }

  const studentInfo = useMemo(() => {
    if (!currentStudent) return {}
    const { studentInfo, fatherInfo } = currentStudent
    return {
      name: studentInfo?.name || '',
      fatherName: fatherInfo?.name || '',
      grade: studentInfo?.grade || '',
      dob: formatDate(studentInfo?.dob) || '',
      status: currentStudent.status || 'Waiting',
    }
  }, [currentStudent])

  if (isLoading || !currentStudent) {
    return (
      <Layout>
        <Topbar title='Loading...' />
      </Layout>
    )
  }

  return (
    <Layout>
      <Topbar title='Fee Details' />
      <Wrap>
        <WrapBar>
          <BarLeft>
            <BarContent>
              <Text>Name: {studentInfo.name}</Text>
              <Text>Guardian: {studentInfo.fatherName}</Text>
              <Text>Class: {studentInfo.grade}</Text>
              <Text>Date of Birth: {studentInfo.dob}</Text>
            </BarContent>
          </BarLeft>
          <BarRight>
            <TextBig>STUDENT Profile</TextBig>
          </BarRight>
        </WrapBar>
      </Wrap>
      {studentInfo.status === 'Waiting' ? (
        <FeeSlipMaker
          onAddItems={addItemsToSlip}
          dueDate={dueDate}
          onDueDateChange={handleDueDateChange}
          currentStudent={currentStudent}
        />
      ) : (
        <FeeMaker
          onAddItems={addItemsToSlip}
          dueDate={dueDate}
          onDueDateChange={handleDueDateChange}
          currentStudent={currentStudent}
        />
      )}
      {/* Fee slip details rendering code... */}
    </Layout>
  )
}

export default FeeSlipDetails
