import { Add, Download, Visibility } from '@mui/icons-material'
import { DataGrid } from '@mui/x-data-grid'

import * as React from 'react'
import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import Loader from '../../../../Loader/Loader'
import { axiosInstance } from '../../../../config'
// styling of tables
const Wrap = styled.div`
  height: 100%;
  width: 95%;
  border-radius: 10px;
`
const GreenBtn = styled.button`
  background-color: #058d19;
  padding: 0.5rem 0.8rem;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
`
const RedBtn = styled(GreenBtn)`
  background-color: yellow;
  color: #000000;
  margin-right: 15px;
`
const GenBtn = styled(RedBtn)`
  /* background-color: #f706b7; */
  background-color: #06f7e7;
  color: #000000;
`
const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`

const WrapBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LabelWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BtnSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const GenerateBtn = styled.button`
  background-color: #34ff0177;
  color: #000000;
  max-width: 100px;
  min-width: 60px;
  font-weight: 400;
  color: #04a80f;
  padding: 0.7rem 0.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`
const DeleteBtn = styled(GenerateBtn)`
  background-color: #fd2f2f39;
  color: red;
`

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export default function EnrolledHistoryDataTable() {
  const [statusFilter, setStatusFilter] = useState('All') // Initial state for showing all students

  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const params = useParams()

  const handleGenerateFeeSlip = (event, studentId) => {
    event.stopPropagation() // This stops the event from propagating further.
    navigate(`/students/enrolled/invoice/${studentId}`)
  }

  const handleDeleteInvoice = async (event, studentId) => {
    event.stopPropagation() // This stops the event from propagating further.

    setIsLoading(true)
    try {
      await axiosInstance.put(`/fee/invoice/${studentId}/delete`)
      await queryClient.invalidateQueries('fee-history-individual')
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
  }

  // tables code
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'paymentDate', headerName: 'Payment Date', width: 130 },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.status ? 'blue' : 'white',
            backgroundColor: params.row.status ? '#060dd039' : 'black',
            padding: '0.5rem',
            borderRadius: '10px',
          }}
        >
          {params.row.status ? 'Paid' : 'Not Paid'}
        </span>
      ),
    },
    {
      field: 'fromAndTo',
      headerName: 'From and To',
      width: 150,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 130,
      sortable: false,
    },
    {
      field: 'barcodeValue',
      headerName: 'Invoice',
      width: 150,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <GenerateBtn
            variant='contained'
            color='primary'
            onClick={(event) => handleGenerateFeeSlip(event, params.row._id)}
          >
            View
          </GenerateBtn>
        )
      },
    },
    {
      field: 'Remove',
      headerName: 'Delete',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <DeleteBtn
            variant='contained'
            color='primary'
            onClick={(event) => handleDeleteInvoice(event, params.row._id)}
          >
            Delete
          </DeleteBtn>
        )
      },
    },
  ]

  const { data, status } = useQuery(
    `fee-history-individual-person`,
    async () => {
      const res = await axiosInstance.get(`/fee/feeslips-student/${params.id}`)
      return res.data
    }
  )
  if (status === 'loading') {
    return (
      <LoaderWrap>
        <Loader msg='Loading Invoices...' />
      </LoaderWrap>
    )
  }

  let counter = 1

  const rows = data
    .filter((item) => statusFilter === 'All' || item.status === statusFilter)
    .map((item) => ({
      id: counter++,
      _id: item._id,
      paymentDate: formatDate(item.paymentDate),
      fromAndTo: item.fromAndTo,
      status: item.isPaid,
      amount: item.totalAmount,
      barcodeValue: item.barcodeValue,
      dueDate: formatDate(item.dueDate),
    }))

  const handleExportToExcel = () => {
    // Map rows with the correct header names
    const mappedRows = rows.map((row) => ({
      ID: row.id,
      'Payment Date': row.paymentDate,
      Status: row.status ? 'Paid' : 'Not Paid',
      'Fee Type': row.feeType,
      Amount: row.amount,
      Invoice: row.barcodeValue,
      'Due Date': row.dueDate,
    }))

    // Create a worksheet with headers
    const ws = XLSX.utils.json_to_sheet(mappedRows, {
      header: Object.keys(mappedRows[0]),
    })

    const csv = XLSX.utils.sheet_to_csv(ws)
    const blob = new Blob([csv], { type: 'text/csv' })

    // Generate the current date string
    const currentDate = new Date().toISOString().split('T')[0] // Format: YYYY-MM-DD

    // Create a download link and click it with a dynamic file name
    const a = document.createElement('a')
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = `fee_history_${currentDate}.csv`
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <>
      <Wrap>
        <WrapBtn>
          <LabelWrap>{/* <GenBtn>Generator</GenBtn> */}</LabelWrap>
          <BtnSet>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/students/enrolled/fee-maker/${params.id}`}
            >
              <GenBtn>
                <Add style={{ marginRight: '5px' }} />
                Create Fee
              </GenBtn>
            </Link>
            <Link
              to={`/students/all-admissions/fee-challan/recycle/${params.id}`}
              style={{ textDecoration: 'none' }}
            >
              <RedBtn>
                <Visibility style={{ marginRight: '5px' }} /> View Deleted
                Invoices
              </RedBtn>
            </Link>
            <GreenBtn
              onClick={handleExportToExcel}
              variant='outlined'
              color='primary'
            >
              <Download style={{ marginRight: '5px' }} /> Save to Excel
            </GreenBtn>
          </BtnSet>
        </WrapBtn>

        <DataGrid
          id='data-grid'
          rows={rows}
          columns={columns}
          pageSize={5}
          onRowClick={(param) =>
            navigate(`/students/enrolled/invoice/${param.row._id}`)
          }
          style={{
            borderRadius: '10px',
            fontSize: '0.9rem',
            backgroundColor: 'white',
            padding: '0 1rem',
          }}
        />
      </Wrap>
    </>
  )
}
