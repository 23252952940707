import React from 'react'
import Layout from '../../../Layout'
import Topbar from '../../../components/Layout/Topbar'
import EnrolledDataTable from '../../../components/Students/Enrolled/EnrolledDataTable'

const Enrolled = () => {
  return (
    <Layout>
      <div className='flex flex-col h-screen'>
        <Topbar title='Students' />
        <div className='flex-grow overflow-hidden'>
          <div className='h-full overflow-auto p-4'>
            <EnrolledDataTable />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Enrolled
