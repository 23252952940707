import { BookOpen, Loader, Lock, Rocket, User } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../config'
import { loginFailure, loginStart, loginSuccess } from '../redux/userSlice'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentUser, loading } = useSelector((state) => state.user)

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard')
    }
  }, [currentUser, navigate])

  const handleLogin = async (e) => {
    e.preventDefault()
    setError('')
    dispatch(loginStart())
    try {
      const res = await axiosInstance.post('/auth/signin', { email, password })
      dispatch(loginSuccess(res.data.user))
    } catch (err) {
      console.error('Login error:', err)
      const errorMessage =
        err.response?.data?.message || 'An error occurred during login'
      dispatch(loginFailure())
      setError(errorMessage)
    }
  }

  return (
    <div className='min-h-screen flex flex-col items-center justify-center bg-[#0b0a45] px-4 py-12'>
      <div className='w-full max-w-md mb-8'>
        <div className='text-center'>
          <Rocket className='text-[#155d31] inline-block mb-4' size={60} />
          <h1 className='text-5xl font-extrabold text-white tracking-tight'>
            THE ORION
          </h1>
          <h2 className='text-3xl font-bold text-[#155d31] mt-2'>SCHOOL</h2>
        </div>
      </div>
      <div className='w-full max-w-md'>
        <div className='bg-white bg-opacity-5 rounded-3xl shadow-2xl backdrop-blur-lg overflow-hidden transform hover:scale-105 transition-all duration-300'>
          <div className='p-8'>
            <div className='flex items-center justify-center mb-6'>
              <BookOpen className='text-[#155d31] mr-2' size={24} />
              <h3 className='text-2xl font-semibold text-white'>
                Faculty Portal
              </h3>
            </div>
            <form onSubmit={handleLogin} className='space-y-6'>
              <div className='relative'>
                <User
                  className='absolute top-3 left-3 text-[#155d31]'
                  size={20}
                />
                <input
                  type='email'
                  id='email'
                  required
                  className='w-full bg-white bg-opacity-10 text-white placeholder-gray-300 border-2 border-[#155d31] rounded-lg focus:border-white focus:ring-2 focus:ring-white outline-none transition-all duration-300 py-2 pl-10 pr-4'
                  placeholder='Faculty Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
              </div>
              <div className='relative'>
                <Lock
                  className='absolute top-3 left-3 text-[#155d31]'
                  size={20}
                />
                <input
                  type='password'
                  id='password'
                  required
                  className='w-full bg-white bg-opacity-10 text-white placeholder-gray-300 border-2 border-[#155d31] rounded-lg focus:border-white focus:ring-2 focus:ring-white outline-none transition-all duration-300 py-2 pl-10 pr-4'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && (
                <p className='text-red-400 text-sm italic bg-red-900 bg-opacity-30 p-2 rounded'>
                  {error}
                </p>
              )}
              <button
                type='submit'
                className='w-full bg-[#155d31] hover:bg-[#1a7a3e] text-white font-bold py-3 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center'
                disabled={loading}
              >
                {loading ? (
                  <Loader className='animate-spin mr-2' size={20} />
                ) : (
                  <Rocket className='mr-2' size={20} />
                )}
                <span>
                  {loading ? 'Accessing...' : 'Access Faculty Portal'}
                </span>
              </button>
            </form>
          </div>
          <div className='w-full h-2 bg-gradient-to-r from-[#0b0a45] to-[#155d31]'></div>
        </div>
      </div>
    </div>
  )
}

export default Login
