import React from 'react'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { dummyData } from '../../utils/dummyData'

const FeesChart = () => (
  <div className='bg-white/10 rounded-lg p-6'>
    <h2 className='text-xl font-semibold mb-4'>Fees Remaining Class Wise</h2>
    <ResponsiveContainer width='100%' height={300}>
      <LineChart data={dummyData.feesByClass}>
        <XAxis dataKey='class' stroke='#fff' />
        <YAxis stroke='#fff' />
        <Tooltip
          contentStyle={{ backgroundColor: '#0b0a45', border: 'none' }}
        />
        <Line type='monotone' dataKey='fees' stroke='#16a34a' strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  </div>
)

export default FeesChart
