import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../../../config'
// FOR ENROLLED STUDENTS
const FeeMaker = ({ onAddItems, dueDate, onDueDateChange, currentStudent }) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [allMonthsPaid, setAllMonthsPaid] = useState([])
  const [selectedMonths, setSelectedMonths] = useState([])

  const [regularFee, setRegularFee] = useState(11500)
  const [othersFee, setOthersFee] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [isPaid, setIsPaid] = useState(true)
  const [recentBarCode, setRecentBarCode] = useState('')
  const navigate = useNavigate()
  const params = useParams()
  const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  useEffect(() => {
    setRegularFee(
      ['PG', 'Nursery', 'KG'].includes(currentStudent.studentInfo.gradeName)
        ? 12500
        : 11500
    )
  }, [currentStudent.studentInfo.gradeName])

  const formatSelectedMonths = () => {
    if (selectedMonths.length === 0) return 'No months selected.'
    const sortedMonths = selectedMonths.sort(
      (a, b) => monthsList.indexOf(a) - monthsList.indexOf(b)
    )
    const firstMonth = sortedMonths[0]
    const lastMonth = sortedMonths[sortedMonths.length - 1]
    return `${firstMonth} to ${lastMonth}`
  }

  useQuery(
    'barcode-data',
    async () => {
      const res = await axiosInstance.get(`/fee/barcode`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRecentBarCode(data.barcodeValue)
      },
    }
  )

  useQuery(
    'student-regular-fee',
    async () => {
      const res = await axiosInstance.get(`/fee/feeslips-student/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setRegularFee(data[0].items[0].baseAmount)
        const combinedMonthsPaid = data.reduce((acc, current) => {
          return [...acc, ...current.monthsPaid]
        }, [])
        setAllMonthsPaid([...new Set(combinedMonthsPaid)])
      },
    }
  )

  useEffect(() => {
    const newItems = [
      {
        id: 1,
        description: 'Regular Fee',
        amount: regularFee * selectedMonths.length,
        isChecked: true,
      },
      {
        id: 2,
        description: 'Others Fee',
        amount: othersFee,
        isChecked: othersFee > 0,
      },
    ].filter((item) => item.amount > 0)

    setSelectedItems(newItems)
    setTotalAmount(newItems.reduce((acc, item) => acc + item.amount, 0))
  }, [regularFee, othersFee, selectedMonths.length])

  const handleMonthChange = (month) => {
    const monthIndex = monthsList.indexOf(month)
    if (selectedMonths.length === 0) {
      setSelectedMonths([month])
    } else {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const lastSelectedMonthIndex =
        sortedSelectedMonthIndices[sortedSelectedMonthIndices.length - 1]

      if (monthIndex === lastSelectedMonthIndex + 1) {
        setSelectedMonths([...selectedMonths, month])
      } else if (monthIndex < lastSelectedMonthIndex) {
        const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]
        if (monthIndex === firstSelectedMonthIndex - 1) {
          setSelectedMonths([month, ...selectedMonths])
        }
      } else if (
        selectedMonths.includes(month) &&
        monthIndex === lastSelectedMonthIndex
      ) {
        const newSelectedMonths = selectedMonths.filter((m) => m !== month)
        setSelectedMonths(newSelectedMonths)
      }
    }
    updateDueDateBasedOnSelection()
  }

  const updateDueDateBasedOnSelection = () => {
    if (selectedMonths.length > 0) {
      const sortedSelectedMonthIndices = selectedMonths
        .map((m) => monthsList.indexOf(m))
        .sort((a, b) => a - b)
      const firstSelectedMonthIndex = sortedSelectedMonthIndices[0]

      const dueDate = new Date()
      dueDate.setMonth(firstSelectedMonthIndex + 1)
      dueDate.setDate(10)

      if (dueDate.getMonth() === 0) {
        dueDate.setFullYear(dueDate.getFullYear() + 1)
      }

      const dueDateString = dueDate.toLocaleDateString('en-CA')
      onDueDateChange({ target: { value: dueDateString } })
    } else {
      onDueDateChange({ target: { value: '' } })
    }
  }

  useEffect(() => {
    updateDueDateBasedOnSelection()
  }, [selectedMonths])

  const handleAddItems = async () => {
    const items = [
      {
        id: 1,
        description: 'Regular Fee',
        baseAmount: regularFee,
        total: regularFee * selectedMonths.length,
      },
      {
        id: 2,
        description: 'Other Fee',
        baseAmount: othersFee,
        total: othersFee,
      },
    ].filter((item) => item.total > 0)

    const monthRange = formatSelectedMonths()
    const totalAmount = items.reduce((acc, item) => acc + item.total, 0)
    const incrementedBarcode = (parseInt(recentBarCode, 10) + 1)
      .toString()
      .padStart(recentBarCode.length, '0')

    const data = {
      studentId: params.id,
      items,
      fromAndTo: monthRange,
      monthsPaid: selectedMonths,
      dueDate: new Date(dueDate),
      barcodeValue: incrementedBarcode,
      feeType: 'Admission',
      totalAmount,
      isPaid,
      paymentDate: isPaid ? new Date() : null,
    }

    try {
      await axiosInstance.post('fee/feeslips/', data)
      navigate(`/students/enrolled/fee-history/${params.id}`)
      alert('Payment Received Successfully')
    } catch (error) {
      alert(`Payment Error (${error})`)
    }
  }

  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='bg-white shadow-lg rounded-lg overflow-hidden'>
        <div className='bg-blue-600 text-white p-4'>
          <h2 className='text-2xl font-bold'>Pay Fee</h2>
        </div>
        <div className='p-6'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>
                Due Date
              </label>
              <input
                type='date'
                value={dueDate}
                onChange={onDueDateChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>
                Regular Fee
              </label>
              <input
                type='number'
                value={regularFee}
                onChange={(e) => setRegularFee(Number(e.target.value))}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>
                Other Fee
              </label>
              <input
                type='number'
                value={othersFee}
                onChange={(e) => setOthersFee(Number(e.target.value))}
                className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-2'>
                Payment Status
              </label>
              <div className='flex items-center'>
                <input
                  type='checkbox'
                  checked={isPaid}
                  onChange={(e) => setIsPaid(e.target.checked)}
                  className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                />
                <span className='ml-2 text-gray-700'>Paid</span>
              </div>
            </div>
          </div>

          <div className='mt-6'>
            <h3 className='text-lg font-medium text-gray-900 mb-2'>
              Select Months
            </h3>
            <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4'>
              {monthsList
                .filter((month) => !allMonthsPaid.includes(month))
                .map((month) => (
                  <button
                    key={month}
                    onClick={() => handleMonthChange(month)}
                    className={`px-3 py-2 rounded-md text-sm font-medium ${
                      selectedMonths.includes(month)
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {month}
                  </button>
                ))}
            </div>
          </div>

          <div className='mt-6'>
            <h3 className='text-lg font-medium text-gray-900 mb-2'>
              Fee Summary
            </h3>
            <div className='bg-gray-100 rounded-md p-4'>
              <div className='flex justify-between mb-2'>
                <span className='font-medium'>Selected Months:</span>
                <span>{formatSelectedMonths()}</span>
              </div>
              <div className='flex justify-between mb-2'>
                <span className='font-medium'>Regular Fee:</span>
                <span>Rs. {regularFee * selectedMonths.length}</span>
              </div>
              <div className='flex justify-between mb-2'>
                <span className='font-medium'>Other Fee:</span>
                <span>Rs. {othersFee}</span>
              </div>
              <div className='flex justify-between font-bold text-lg'>
                <span>Total Amount:</span>
                <span>Rs. {totalAmount}</span>
              </div>
            </div>
          </div>

          <div className='mt-6'>
            <button
              onClick={handleAddItems}
              className='w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeeMaker
