import {
  Delete,
  Edit,
  KeyboardBackspace,
  Visibility,
} from '@mui/icons-material'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../Layout'
import Loader from '../../../Loader/Loader'
import Topbar from '../../../components/Layout/Topbar'
import { axiosInstance } from '../../../config'

const InputWrap = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 8px; */
`

const FormText = styled.span`
  font-weight: 700;
  min-width: 110px;
`

const FormContent = styled.p`
  font-weight: 600;
  width: 100%;
  border-bottom: 0.5px solid #00000096;
  padding: 10px 0;
  min-height: 18px;
  color: #000000;
  outline: none;
  background: transparent;
  padding: 0.3rem;
  /* border-radius: 5px; */
  min-width: 200px;
`

const InputSet = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: capitalize;
`
const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0.2rem 0;
  padding: 0 0.4rem;
`

const FieldsHeading = styled.p`
  font-size: 1rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
  color: #000000;
  padding: 0.3rem;
`
const SubSet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const SubmitBtn = styled.button`
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  padding: 0.6rem 1.4rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 17px;
  border-radius: 300px;
  color: white;
  margin-left: 10px;
  margin-top: 5px;
  min-width: 150px;
  &.green {
    background-color: green;
  }
  &.blue {
    background-color: blue;
  }
  &.red {
    background-color: red;
  }
  &.darkGreen {
    background-color: #003700;
  }
`
const CardBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 7, 35, 0.829); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`
const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const LogoImg = styled.img`
  width: 70px;
  min-width: 40px;
`
const LogoInfo = styled.p`
  font-size: 10px;
  text-transform: lowercase;
  font-weight: 300;
`
const FooterInfo = styled(LogoInfo)`
  margin-top: 4rem;
`
const SplitBox = styled.div`
  width: 100%;
  font-weight: 800;
  letter-spacing: 2px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  padding: 0.5rem;
  margin: 10px 0;
  margin-top: 12px;
  border: 1px solid black;
`
const A4Wrap = styled.div`
  width: 210mm;
  margin: 0 auto;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  @media print {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    box-shadow: none;
    background: none;
  }
`

const PrintContent = styled.div`
  padding: 20mm;

  @media print {
    padding: 15mm;
  }
`

const ClassSelectionInput = styled.select`
  padding: 0.5rem;
  margin-left: 10px;
  width: 200px;
  outline: none;
  font-size: 0.9rem;
`

const ViewEnrolled = () => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const [studentInfo, setStudentInfo] = useState({})
  const [fatherInfo, setFatherInfo] = useState({})
  const [motherInfo, setMotherInfo] = useState({})
  const [familyInfo, setFamilyInfo] = useState({})
  const [extracurricular, setExtracurricular] = useState({})
  const [medicalHistory, setMedicalHistory] = useState({})
  const navigate = useNavigate()

  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.put(`/student/delete-by-id/${params.id}`)
      navigate('/students/enrolled')
    } catch (err) {
      console.log(err)
    }
  }

  // get request data
  const { data, status } = useQuery(
    'specific-view-student',
    async () => {
      const res = await axiosInstance.get(`/admission/${params.id}`)
      console.log(res.data)
      return res.data
    },
    {
      onSuccess: (data) => {
        setStudentInfo(data.studentInfo || {})
        setFatherInfo(data.fatherInfo || {})
        setMotherInfo(data.motherInfo || {})
        setFamilyInfo(data.familyInfo || {})
        setExtracurricular(data.extracurricular || { sports: [], clubs: [] })
        setMedicalHistory(data.medicalHistory || {})
        setIsLoading(false)
      },
    }
  )

  return (
    <>
      <style>
        {`
          @page {
            size: A4;
            margin: 0;
          }
          @media print {
            html, body {
              width: 210mm;
              height: auto;
            }
            body {
              margin: 0;
              padding: 0;
            }
            body * {
              visibility: hidden;
            }
            #booking-form-wrap, #booking-form-wrap * {
              visibility: visible;
            }
            #booking-form-wrap {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: auto;
            }
            .SubSet, .SubSet * { 
              display: none !important;
            }
          }
        `}
      </style>

      <Layout>
        <Topbar title='Student / Profile' />
        <form>
          <A4Wrap id='booking-form-wrap'>
            <PrintContent>
              <LogoWrap>
                <LogoImg src='https://github.com/furqanistic/ogs-client/blob/website/faculty/src/img/logobw.png?raw=true' />
                <FieldsHeading>The Orion School </FieldsHeading>
                <LogoInfo>www.theorionschool.com</LogoInfo>
                <LogoInfo>+92 307 7879977</LogoInfo>
                <LogoInfo>help@orionedu.net</LogoInfo>
              </LogoWrap>
              <SplitBox>Student Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Name: </FormText>
                    <FormContent>{studentInfo.name || ' '}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Admission #: </FormText>
                    <FormContent>{data?.admissionNumber || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Grade: </FormText>
                    <FormContent>{studentInfo.grade || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Gender: </FormText>
                    <FormContent>{studentInfo.gender || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>DOB: </FormText>
                    <FormContent>
                      {formatDate(studentInfo.dob) || ' '}
                    </FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Previous School: </FormText>
                    <FormContent>{studentInfo.prevSchool || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Nationality: </FormText>
                    <FormContent>{studentInfo.nationality || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Father's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Name: </FormText>
                    <FormContent>{fatherInfo.name || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>CNIC: </FormText>
                    <FormContent>{fatherInfo.cnic || ''}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Cell#: </FormText>
                    <FormContent>{fatherInfo.cell || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Company: </FormText>
                    <FormContent>{fatherInfo.company || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Occupation: </FormText>
                    <FormContent>{fatherInfo.occupation || ' N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Email: </FormText>
                    <FormContent>{fatherInfo.email || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Nationality: </FormText>
                    <FormContent>{fatherInfo.nationality || ' '}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Education: </FormText>
                    <FormContent>{fatherInfo.education || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Mother's Profile</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Name: </FormText>
                    <FormContent>{motherInfo.name || ' N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Cell#: </FormText>
                    <FormContent>{motherInfo.cell || ' N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Occupation: </FormText>
                    <FormContent>{motherInfo.occupation || ' N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Company: </FormText>
                    <FormContent>{motherInfo.company || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Education: </FormText>
                    <FormContent>{motherInfo.education || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Is Working: </FormText>
                    <FormContent>
                      {motherInfo.isWorking ? 'Yes' : 'No'}
                    </FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Job: </FormText>
                    <FormContent>{motherInfo.job || 'N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Other Information</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Address: </FormText>
                    <FormContent>{familyInfo.address || ' N/A'}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>First Language: </FormText>
                    <FormContent>{familyInfo.firstLanguage || ' '}</FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Other Languages: </FormText>
                    <FormContent>
                      {familyInfo.otherLanguages?.join(', ') || ' '}
                    </FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Sports & Club Preferences</SplitBox>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Sports Interested In: </FormText>
                    <FormContent>
                      {extracurricular.sports?.join(', ') || 'None'}
                    </FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <InputWrap>
                <ErrorWrap>
                  <InputSet>
                    <FormText>Clubs Interested In: </FormText>
                    <FormContent>
                      {extracurricular.clubs?.join(', ') || 'None'}
                    </FormContent>
                  </InputSet>
                </ErrorWrap>
              </InputWrap>
              <SplitBox>Siblings Information</SplitBox>
              {familyInfo.siblings && familyInfo.siblings.length > 0
                ? familyInfo.siblings.map((sibling, index) => (
                    <InputWrap key={index}>
                      <ErrorWrap>
                        <InputSet>
                          <FormText>Sibling ({index + 1}): </FormText>
                          <FormContent>
                            {sibling.name || 'N/A'} - {sibling.grade || 'N/A'} -{' '}
                            {sibling.section || 'N/A'}
                          </FormContent>
                        </InputSet>
                      </ErrorWrap>
                    </InputWrap>
                  ))
                : Array.from({ length: 4 }).map((_, index) => (
                    <InputWrap key={index}>
                      <ErrorWrap>
                        <InputSet>
                          <FormText>Sibling ({index + 1}): </FormText>
                          <FormContent>N/A - N/A - N/A</FormContent>
                        </InputSet>
                      </ErrorWrap>
                    </InputWrap>
                  ))}
              <SplitBox style={{ marginTop: '5rem' }}>Medical History</SplitBox>
              {Object.entries(medicalHistory).map(([key, value]) => (
                <InputWrap key={key}>
                  <ErrorWrap>
                    <InputSet>
                      <FormText>
                        {key
                          .split(/(?=[A-Z])/)
                          .join(' ')
                          .charAt(0)
                          .toUpperCase() +
                          key
                            .split(/(?=[A-Z])/)
                            .join(' ')
                            .slice(1)}
                        :{' '}
                      </FormText>
                      <FormContent>
                        {typeof value === 'boolean'
                          ? value
                            ? 'Yes'
                            : 'No'
                          : value || 'N/A'}
                      </FormContent>
                    </InputSet>
                  </ErrorWrap>
                </InputWrap>
              ))}
              <LogoWrap>
                <FooterInfo>
                  www.theorionschool.com | +92 307 7879977 | help@orionedu.net
                </FooterInfo>
              </LogoWrap>
              {isLoading && (
                <CardBase>
                  <Loader msg='Loading Student Profile Card...' />
                </CardBase>
              )}
              <div className='page-break'></div>
            </PrintContent>
          </A4Wrap>
          <SubSet>
            <SubmitBtn className='green' onClick={goBack}>
              <KeyboardBackspace style={{ marginRight: '5px' }} />
              Back
            </SubmitBtn>
            <Link to={`/students/enrolled/edit/${params.id}`}>
              <SubmitBtn className='blue'>
                <Edit style={{ marginRight: '5px' }} />
                Edit
              </SubmitBtn>
            </Link>
            <SubmitBtn className='red' onClick={handleDelete}>
              <Delete style={{ marginRight: '5px' }} />
              Delete
            </SubmitBtn>
            <SubmitBtn className='darkGreen' onClick={() => window.print()}>
              <Visibility style={{ marginRight: '5px' }} />
              Print
            </SubmitBtn>
          </SubSet>
        </form>
      </Layout>
    </>
  )
}

export default ViewEnrolled
