import { ArrowLeft, Pencil, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Topbar from '../../components/Layout/Topbar'
import { axiosInstance } from '../../config.js'
import Layout from '../../Layout'

const ViewTeacher = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [teacherData, setTeacherData] = useState({})

  const { status } = useQuery(
    'specific-teacher',
    async () => {
      const res = await axiosInstance.get(`/teacher/${params.id}`)
      return res.data
    },
    {
      onSuccess: (data) => {
        setTeacherData(data)
        setIsLoading(false)
      },
    }
  )

  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.delete(`/teacher/${params.id}`)
      navigate('/teachers')
    } catch (err) {
      console.error(err)
    }
  }

  const InfoField = ({ label, value }) => (
    <div className='mb-4'>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <p className='mt-1 p-2 w-full border border-gray-300 rounded-md bg-gray-50'>
        {value || 'N/A'}
      </p>
    </div>
  )

  if (isLoading) {
    return (
      <div className='fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center'>
        <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white'></div>
      </div>
    )
  }

  return (
    <Layout>
      <Topbar title='Teachers' />
      <div className='min-h-screen py-6 flex flex-col justify-center sm:py-12 px-4 sm:px-6 lg:px-8'>
        <div className='w-full'>
          <div className='relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20 w-full max-w-5xl mx-auto'>
            <div className='max-w-md mx-auto'>
              <h1 className='text-2xl font-semibold text-center mb-6'>
                Teacher Information
              </h1>

              <InfoField label='First Name' value={teacherData.fname} />
              <InfoField label='Last Name' value={teacherData.lname} />
              <InfoField label='Gender' value={teacherData.gender} />
              <InfoField label='Cell#' value={teacherData.cell} />
              <InfoField label='School Email' value={teacherData.scEmail} />
              <InfoField label='Personal Email' value={teacherData.gmail} />
              <InfoField label='Address' value={teacherData.address} />
              <InfoField label='Department' value={teacherData.department} />
              <InfoField label='Other Information' value={teacherData.others} />

              <div className='mt-8 flex flex-wrap justify-center gap-4'>
                <button
                  onClick={() => navigate(-1)}
                  className='flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors'
                >
                  <ArrowLeft className='mr-2' size={18} />
                  Back
                </button>
                <Link
                  to={`/teachers/edit/${params.id}`}
                  className='flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors'
                >
                  <Pencil className='mr-2' size={18} />
                  Edit
                </Link>
                <button
                  onClick={handleDelete}
                  className='flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors'
                >
                  <Trash className='mr-2' size={18} />
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ViewTeacher
