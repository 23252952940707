import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout from '../../../../Layout'
import Topbar from '../../../../components/Layout/Topbar'
import HistoryDataTable from '../../../../components/Students/Admission/Fee/HistoryDataTable'
import { axiosInstance } from '../../../../config'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 1rem 2rem 2rem 0rem;
  border-radius: 10px;
  border: 1px solid white;
`

const BarLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
`

const BarRight = styled(BarLeft)`
  justify-content: flex-end;
  padding: 2rem;
`

const BarContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 1rem;
  flex-direction: column;
`

const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
`

const TextBig = styled.p`
  font-size: 3rem;
  font-weight: 700;
`

const LoadingText = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 2rem;
  color: white;
`

const ViewFeeHistory = () => {
  const params = useParams()

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const {
    data: currentStudent,
    isLoading,
    isError,
  } = useQuery(['fee-history-person', params.id], async () => {
    const res = await axiosInstance.get(`/admission/${params.id}`)
    return res.data
  })

  if (isLoading) {
    return (
      <Layout>
        <Topbar title='Fee History' />
        <LoadingText>Loading student data...</LoadingText>
      </Layout>
    )
  }

  if (isError) {
    return (
      <Layout>
        <Topbar title='Fee History' />
        <LoadingText>
          Error fetching student data. Please try again.
        </LoadingText>
      </Layout>
    )
  }

  const { studentInfo, fatherInfo } = currentStudent

  return (
    <Layout>
      <Topbar title='Fee History' />
      <Wrap>
        <WrapBar>
          <BarLeft>
            <BarContent>
              <Text>Name : {studentInfo.name}</Text>
              <Text>Guardian : {fatherInfo.name}</Text>
              <Text>Class : {studentInfo.grade}</Text>
              <Text>Date of Birth : {formatDate(studentInfo.dob)}</Text>
            </BarContent>
          </BarLeft>
          <BarRight>
            <TextBig>STUDENT HISTORY</TextBig>
          </BarRight>
        </WrapBar>
        <HistoryDataTable currentStudent={currentStudent} />
      </Wrap>
    </Layout>
  )
}

export default ViewFeeHistory
