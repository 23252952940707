import { motion } from 'framer-motion'
import React from 'react'
import Layout from '../Layout'
import AnnouncementsCard from '../components/Dashboard/AnnouncementsCard'
import FeesChart from '../components/Dashboard/FeesChart'
import InfoCardGrid from '../components/Dashboard/InfoCardGrid'
import MonthlyFeesCard from '../components/Dashboard/MonthlyFeesCard'
import StudentDistributionAndPerformanceChart from '../components/Dashboard/StudentDistributionAndPerformanceChart'
import Topbar from '../components/Layout/Topbar'

const Dashboard = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
  }

  const cardVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 50,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
      },
    },
  }

  const chartVariants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 20,
        stiffness: 100,
      },
    },
  }

  return (
    <Layout>
      <Topbar title='Dashboard' />
      <motion.div
        className='min-h-screen bg-[#0b0a45] text-white p-4'
        initial='hidden'
        animate='visible'
        variants={containerVariants}
      >
        <div className='max-w-7xl mx-auto'>
          <motion.div
            variants={cardVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <InfoCardGrid />
          </motion.div>

          <motion.div
            className='grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6'
            variants={containerVariants}
          >
            <motion.div
              className='lg:col-span-2'
              variants={chartVariants}
              whileHover={{ scale: 1.02 }}
            >
              <FeesChart />
            </motion.div>
            <MonthlyFeesCard />
          </motion.div>

          <motion.div
            className='grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6'
            variants={containerVariants}
          >
            <motion.div
              className='lg:col-span-2'
              variants={chartVariants}
              whileHover={{ scale: 1.02 }}
            >
              <StudentDistributionAndPerformanceChart />
            </motion.div>
            <motion.div
              variants={cardVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <AnnouncementsCard />
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </Layout>
  )
}

export default Dashboard
