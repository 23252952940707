import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Sidebar from './components/Layout/Sidebar'

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', handleResize)
    handleResize() // Call it initially
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='flex h-screen bg-[#0b0a45]'>
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <motion.div
        className={`flex flex-col flex-grow ${isSidebarOpen ? 'lg:ml-60' : ''}`}
        layout
        transition={{
          type: 'spring',
          stiffness: 350,
          damping: 30,
        }}
      >
        <motion.main
          className='flex-grow p-4 overflow-auto'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: 'easeOut',
          }}
        >
          {children}
        </motion.main>
      </motion.div>
    </div>
  )
}

export default Layout
